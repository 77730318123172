import type {TemplateResult} from 'lit';
import {html, svg} from 'lit';
import {classMap} from 'lit/directives/class-map.js';
import {styleMap} from 'lit/directives/style-map.js';
import {msg} from '@lit/localize';
import {getLanguageCode} from '../i18n.ts';
import {modal$} from '../stores/store-main.ts';
import type {Place} from '../apis/api-places.ts';
import type {Sightseeing} from '../apis/api-sightseeings.ts';
import {ifDefined} from 'lit/directives/if-defined.js';
import type {FileType} from '../apis/api-static-pages-and-topics.ts';
import {SBB_URL_BY_LANG} from './constants.ts';

export type ScreenSizeCode =
  | 'zero'
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'mdx'
  | 'mdxx'
  | 'lg'
  | 'xl'
  | 'xxl'
  | 'max';

export const heightProfilePDF = (classes = 'w-4 h-4') => html`
  <svg
    class="${classes}"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      id="Rechteck_3243"
      data-name="Rechteck 3243"
      width="24"
      height="24"
      fill="none"
    />
    <path
      id="icons8-open_document"
      d="M6,2A2.013,2.013,0,0,0,4,4V20a2.013,2.013,0,0,0,2,2H16.172l-1.586-1.586L14.172,20H6V4h7V9h5v3h2V8L14,2ZM18,14v4H15l4,4,4-4H20V14Z"
      transform="translate(-1)"
    />
  </svg>
`;

export const signalisationOneDirectionSvg = (classes = 'w-4 h-4') => html`
  <svg
    class="${classes}"
    alt=""
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rechteck_4770"
          data-name="Rechteck 4770"
          width="19.94"
          height="10.95"
          transform="translate(0 0)"
          fill="none"
        />
      </clipPath>
    </defs>
    <rect
      id="Rechteck_4769"
      data-name="Rechteck 4769"
      width="24"
      height="24"
      fill="none"
    />
    <g id="Gruppe_12643" data-name="Gruppe 12643" transform="translate(2 7)">
      <g id="Gruppe_12642" data-name="Gruppe 12642" clip-path="url(#clip-path)">
        <path
          id="Pfad_13725"
          data-name="Pfad 13725"
          d="M14.255,10.95H.975A.975.975,0,0,1,0,9.975v-9A.975.975,0,0,1,.975,0h13.24a.981.981,0,0,1,.671.267l4.75,4.5a.976.976,0,0,1,0,1.413l-4.71,4.5a.978.978,0,0,1-.674.27M1.95,9H13.865l3.686-3.523L13.827,1.95H1.95ZM3.995,2.975h3a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1h-3a1,1,0,0,1-1-1v-3a1,1,0,0,1,1-1"
        />
      </g>
    </g>
  </svg>
`;

export const notificationSvg = (classes = 'w-4 h-4') => html`
  <svg
    class="${classes}"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      id="Rechteck_3197"
      data-name="Rechteck 3197"
      width="24"
      height="24"
      fill="none"
    />
    <path
      id="icons8-info"
      d="M12,2A10,10,0,1,0,22,12,10.015,10.015,0,0,0,12,2Zm0,2a8,8,0,1,1-8,8A7.985,7.985,0,0,1,12,4ZM11,7V9h2V7Zm0,4v6h2V11Z"
    />
  </svg>
`;

export const angleUpSvg = (classes = 'w-4 h-4') => html`
  <svg
    class="${classes}"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.07 8 6 14.07l1.5 1.5L12.07 11l4.57 4.57 1.5-1.5Z" />
  </svg>
`;

export const triangleUpSvg = (classes = 'w-4 h-4') => html`
  <svg
    class="${classes}"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 12,6.3697004 5.49869,17.630299 c 4.1168741,0 9.165214,0 13.00262,0 z"
    />
  </svg>
`;

export const filterSvg = (classes = 'w-4 h-4') => html`
  <svg
    class="${classes}"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 2a3.008 3.008 0 0 0-2.812 2H2v2h5.188A2.993 2.993 0 1 0 10 2Zm0 2a1 1 0 1 1-1 1 .985.985 0 0 1 1-1Zm5 0v2h7V4Zm0 5a3.008 3.008 0 0 0-2.812 2H2v2h10.188A2.993 2.993 0 1 0 15 9Zm0 2a1 1 0 1 1-1 1 .985.985 0 0 1 1-1Zm5 0v2h2v-2ZM7 16a3.008 3.008 0 0 0-2.812 2H2v2h2.188A2.993 2.993 0 1 0 7 16Zm0 2a1 1 0 1 1-1 1 .985.985 0 0 1 1-1Zm5 0v2h10v-2Z"
    />
  </svg>
`;

export const filterDirtySvg = (classes = 'w-4 h-4') => html`
  <svg
    class="${classes}"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M24 3a3 3 0 1 1-3-3 3 3 0 0 1 3 3" fill="#d4021c" />
    <path
      d="M17.928 6h-2.926V4h1.453a4.008 4.008 0 0 0 1.473 2m-5.74 7H1.994v-2h10.194a2.99 2.99 0 1 1 0 2m1.814-1a1 1 0 1 0 1-1h-.03a.986.986 0 0 0-.971 1m-2 8h10.005v-2H12Zm8-7h2v-2h-2ZM9.825 18a3 3 0 0 1-5.642 2.014H1.994v-2h2.189A2.992 2.992 0 0 1 9.825 18m-1.831 1.01a1 1 0 0 0-1-1h-.03a.986.986 0 0 0-.971 1 1 1 0 0 0 2 0M7.185 6H1.994V4h5.191a2.99 2.99 0 1 1 0 2m1.809-1a1 1 0 1 0 1-1h-.03a.986.986 0 0 0-.97 1"
    />
  </svg>
`;

export const searchSvg = (classes = 'w-4 h-4') => html`
  <svg
    class="${classes}"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 4a14 14 0 1 0 9.148 24.563l.852.852V32l12 12 4-4-12-12h-2.586l-.852-.852A13.971 13.971 0 0 0 18 4Zm0 4A10 10 0 1 1 8 18 9.97 9.97 0 0 1 18 8Z"
    />
  </svg>
`;

export const userSvg = (classes = 'w-4 h-4') => html`
  <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
  >
    <path
      d="M43.734 45.707H39.76a15.894 15.894 0 1 0-31.787 0H4a19.808 19.808 0 0 1 12.059-18.3 13.907 13.907 0 1 1 15.616 0 19.808 19.808 0 0 1 12.059 18.3ZM23.867 5.973a9.933 9.933 0 1 0 9.933 9.934 9.945 9.945 0 0 0-9.933-9.934Z"
    />
  </svg>
`;

export const userDirtySvg = (classes = 'w-4 h-4', pointClass = '') => html`
  <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
  >
    <path class="${pointClass}" d="M48 6a6 6 0 1 1-6-6 6 6 0 0 1 6 6" />
    <path
      d="M43.734 45.707H39.76a15.894 15.894 0 1 0-31.787 0H4a19.808 19.808 0 0 1 12.059-18.3 13.907 13.907 0 1 1 15.616 0 19.808 19.808 0 0 1 12.059 18.3ZM23.867 5.973a9.933 9.933 0 1 0 9.933 9.934 9.945 9.945 0 0 0-9.933-9.934Z"
    />
  </svg>
`;

export const trekkingSvg = ({classes = 'w-4 h-4', ariaLabel = '', alt = ''}) =>
  html` <svg
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    class="${classes}"
    alt=${alt}
    aria-label=${ariaLabel}
  >
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
        .cls-2 {
          clip-path: url(#clippath);
        }
        .cls-3 {
          fill: #fff;
        }
        .cls-4 {
          fill: #609f26;
        }
      </style>
      <clipPath id="clippath">
        <rect class="cls-1" x=".03" y=".02" width="24" height="24" />
      </clipPath>
    </defs>
    <g class="cls-2">
      <path
        class="cls-4"
        d="M.03,12.02C.03,5.39,5.4,.02,12.03,.02s12,5.37,12,12-5.37,12-12,12S.03,18.64,.03,12.02"
      />
      <path
        class="cls-3"
        d="M12.26,2.38c.78,0,1.39,.67,1.39,1.42s-.58,1.43-1.36,1.43c-.87,0-1.39-.71-1.39-1.45s.52-1.4,1.35-1.4m-.38,3.1c-.16,0-.35,.04-.5,.08-.11,.04-.29,.17-.32,.17h-1.77c-.21,0-.62,.21-.75,.3-.06,.04-.05,.09-.05,.14,0,.08,.04,.23,.2,.23,.1,0,.44-.23,.58-.23h.82c.04,.05,.1,.1,.1,.15,0,.04-.04,.13-.1,.17h-.74c-.14,0-.49,.2-.55,.24-.08,.06-.17,.13-.22,.2-.13,.17-.16,.41-.2,.58-.05,.2-.11,.4-.16,.6-.05,.18-.35,1.36-.35,1.51,0,.14,.02,.3,.12,.45,.2,.27,.42,.46,.65,.67,.13,.12,.57,.68,.82,.68h.95l.04,.04v.31h2.91s.01-.03,.03-.04v-.85l1.13,1.21-.53,2.08-.57-2.1h-2.99c-.3,.95-.53,1.95-.8,2.95-.21,.75-.38,1.52-.57,2.26-.14,.55-.71,2.6-.71,2.8,0,.5,.41,.96,.93,.96,.27,0,.59-.09,.81-.46,.13-.21,.14-.44,.2-.63,.11-.38,.21-.79,.31-1.17,.03-.14,.07-.29,.11-.43,.02-.06,0-.14,.02-.2,.38-1.51,.8-3.02,1.18-4.53,.02-.08,.03-.17,.04-.25,0,.01,.02,.04,.03,.05,.13,.46,.25,.93,.37,1.38,0,.04,.01,.09,.02,.13,.25,.93,.52,1.88,.75,2.81l-.76,2.99c0,.12,.07,.23,.19,.23,.05,0,.18-.05,.2-.12,.18-.76,.39-1.52,.57-2.26,.19,.58,.3,1.22,.51,1.81,.02,.06,.02,.14,.05,.2,.04,.07,.1,.14,.15,.18,.13,.12,.42,.19,.58,.19,.52,0,.93-.41,.93-.95,0-.03,0-.2-.03-.3-.43-1.58-1.31-4.72-1.31-4.76s.24-.95,.37-1.43c.12-.43,.2-.89,.35-1.3,.19,.04,.42,0,.59-.1,.23-.16,.33-.37,.35-.56,0-.01,.02,.02,.02-.02v-.35s-.03-.03-.04-.04c-.08-.27-.4-.58-.59-.76-.42-.43-.91-.91-1.25-1.29-.21-.24-.65-.67-.65-.7v-1.63s-.05-.25-.1-.36c-.28-.67-.9-.95-1.39-.95"
      />
    </g>
    <rect class="cls-1" x="0" width="24" height="24" />
    <rect class="cls-1" x="0" width="24" height="24" />
  </svg>`;

export const poiPointSvg = ({
  classes = 'w-4 h-4',
  fill = '#0074d9',
  ariaLabel = '',
  alt = '',
  number = 0,
}) =>
  html` <svg
    class="${classes}"
    alt=${alt}
    aria-label=${ariaLabel}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 33"
    fill="${fill}"
  >
    <path
      fill="currentFill"
      stroke="white"
      stroke-width="2"
      paint-order="stroke"
      d="M18 8.177c-5 0-9.5 3-9.5 9 0 4 9.5 24 9.5 24s9.5-20 9.5-24c0-6-4.5-9-9.5-9Z"
      transform="translate(-8, -7)"
    />
    <text
      x="10"
      y="16"
      text-anchor="middle"
      font-family="Inter Variable"
      font-weight="bold"
      font-size="14"
      fill="white"
    >
      ${number ? number : ''}
    </text>
  </svg>`;

export const maxHeightSvg = ({
  classes = 'w-4 h-24',
  ariaLabel = '',
  alt = '',
}) =>
  html` <svg
    id="i_plus_anzeige_hoehemeter_max"
    xmlns="http://www.w3.org/2000/svg"
    class="${classes}"
    viewBox="0 0 24 24"
    alt=${alt}
    aria-label=${ariaLabel}
  >
    <rect
      id="Rechteck_3792"
      data-name="Rechteck 3792"
      width="24"
      height="24"
      fill="none"
    />
    <path
      id="Pfad_13111"
      data-name="Pfad 13111"
      d="M603.587-10047.04l3.089-6.077,2.26,4.546,6.852-12.624,7.938,14.155"
      transform="translate(-601.661 10066.883)"
      fill="none"
      stroke="#868e96"
      stroke-width="2"
    />
    <circle
      id="Ellipse_738"
      data-name="Ellipse 738"
      cx="2"
      cy="2"
      r="2"
      transform="translate(12 2)"
    />
  </svg>`;

export const minHeightSvg = ({
  classes = 'w-4 h-24',
  ariaLabel = '',
  alt = '',
}) =>
  html` <svg
    id="i_plus_anzeige_hoehemeter_max"
    xmlns="http://www.w3.org/2000/svg"
    class="${classes}"
    viewBox="0 0 24 24"
    alt=${alt}
    aria-label=${ariaLabel}
  >
    <rect
      id="Rechteck_3795"
      data-name="Rechteck 3795"
      width="24"
      height="24"
      fill="none"
    />
    <path
      id="Pfad_13112"
      data-name="Pfad 13112"
      d="M602.533-10045.241l4.144-7.876,5.866,10.5,7.5-10.5"
      transform="translate(-599.727 10060.117)"
      fill="none"
      stroke="#868e96"
      stroke-width="2"
    />
    <circle
      id="Ellipse_739"
      data-name="Ellipse 739"
      cx="2"
      cy="2"
      r="2"
      transform="translate(11 16)"
    />
  </svg>`;

export const tagSvg = ({classes = 'w-4 h-24', ariaLabel = '', alt = ''}) =>
  html` <svg
    id="i_plus_anzeige_hoehemeter_max"
    xmlns="http://www.w3.org/2000/svg"
    class="${classes}"
    viewBox="0 0 24 24"
    alt=${alt}
    aria-label=${ariaLabel}
  >
    <rect
      id="Rechteck_4131"
      data-name="Rechteck 4131"
      width="24"
      height="24"
      fill="none"
    />
    <path
      id="Pfad_13755"
      data-name="Pfad 13755"
      d="M21.62,7.25c-1.16-.21-1.14-.53-1.09-1.59a2.9,2.9,0,0,0-.85-2.65,2.424,2.424,0,0,0-2.22-.51,5.98,5.98,0,0,0-2.93,2.51l-.51-.53a1,1,0,0,0-.91-.28l-2.59.52a.967.967,0,0,0-.52.29L1.7,13.6a1,1,0,0,0-.03,1.36l5.96,6.71a.938.938,0,0,0,.71.33h.03a.984.984,0,0,0,.7-.29l8.81-8.62a1.031,1.031,0,0,0,.28-.51l.55-2.65a.993.993,0,0,0-.26-.9L15.74,6.25a4.9,4.9,0,0,1,2.15-2.11.742.742,0,0,1,.72.19c.24.2.25.54.22,1.26-.05,1.08-.13,2.87,2.49,3.34a.853.853,0,1,0,.3-1.68Zm-4.98,2.8-.38,1.83L8.42,19.55,3.78,14.33l7.44-7.71,1.75-.35.48.5c-.38.69-.69,1.34-.91,1.84a1.382,1.382,0,1,0,1.64,1.35,1.407,1.407,0,0,0-.13-.58c.19-.43.41-.88.65-1.33l1.95,2.01Z"
      transform="translate(0.583 -0.416)"
    />
  </svg>`;

export const categorySvg = ({classes = 'w-4 h-24', ariaLabel = '', alt = ''}) =>
  html`<svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    alt=${alt}
    aria-label=${ariaLabel}
    class="${classes}"
  >
    <path
      d="M1.96 14.11H12.06H1.96ZM13.02 14.11C13.02 13.58 12.59 13.15 12.06 13.15H1.96C1.43 13.15 1 13.58 1 14.11C1 14.64 1.43 15.07 1.96 15.07H12.06C12.59 15.07 13.02 14.64 13.02 14.11ZM1.97 17.98H12.07H1.97ZM13.03 17.98C13.03 17.45 12.6 17.02 12.07 17.02H1.97C1.44 17.02 1.01 17.45 1.01 17.98C1.01 18.51 1.44 18.94 1.97 18.94H12.07C12.6 18.94 13.03 18.51 13.03 17.98ZM2.03 21.96H12.13H2.03ZM13.09 21.96C13.09 21.43 12.66 21 12.13 21H2.03C1.5 21 1.07 21.43 1.07 21.96C1.07 22.49 1.5 22.92 2.03 22.92H12.13C12.66 22.92 13.09 22.49 13.09 21.96ZM22.02 5V16.99C22.02 17.54 21.57 17.99 21.02 17.99H18.96V20.95C18.96 21.49 18.53 21.94 17.98 21.95L14.1 22.04L14.06 20.04L16.97 19.98V10.25H9.99C9.75 10.25 9.53 10.17 9.35 10.01L7.31 8.29H4V11.99H1.99V7.31C1.99 6.76 2.44 6.31 2.99 6.31H4.99V3.02C4.99 2.47 5.44 2.02 5.99 2.02L10.68 2C10.92 2 11.15 2.09 11.33 2.24L13.38 4H21.02C21.57 4 22.02 4.45 22.02 5ZM20.02 6H13.01C12.77 6 12.54 5.91 12.36 5.76L10.31 4H6.99V6.28H7.66C7.85 6.27 8.13 6.36 8.31 6.51L10.35 8.23H17.96C18.51 8.23 18.96 8.68 18.96 9.23V15.98H20.02V6Z"
    />
  </svg> `;

export const toTheMapSvg = ({classes = 'w-4 h-24', ariaLabel = '', alt = ''}) =>
  html` <svg
    xmlns="http://www.w3.org/2000/svg"
    class="${classes}"
    viewBox="0 0 24 24"
    alt=${alt}
    aria-label=${ariaLabel}
  >
    <path
      d="M10.5.75a5.4,5.4,0,0,0-5.4,5.4c0,3.858,5.4,9.9,5.4,9.9s5.4-6.042,5.4-9.9A5.4,5.4,0,0,0,10.5.75Zm0,1.8a3.6,3.6,0,0,1,3.6,3.6c0,1.809-1.875,4.823-3.6,7.073C8.77,10.978,6.9,7.964,6.9,6.15A3.6,3.6,0,0,1,10.5,2.55Zm0,1.8a1.8,1.8,0,1,0,1.8,1.8A1.8,1.8,0,0,0,10.5,4.35Zm-6.478,9L1.5,19.65h18l-2.522-6.3H14.831c-.436.656-.877,1.266-1.28,1.8h2.213l1.078,2.7H4.158l1.078-2.7H7.448c-.4-.534-.844-1.144-1.28-1.8Zm0,0"
      transform="translate(1.5 1.8)"
    />
    <rect width="24" height="24" fill="none" />
  </svg>`;

export const editSvg = ({classes = 'w-4 h-24', ariaLabel = '', alt = ''}) =>
  html` <svg
    xmlns="http://www.w3.org/2000/svg"
    class="${classes}"
    viewBox="0 0 24 24"
    alt=${alt}
    aria-label=${ariaLabel}
  >
    <path
      d="M17.914.5a.993.993 0 0 0-.707.293L2.5 15.5v4h4L21.207 4.793a1 1 0 0 0 0-1.414L18.621.793A.993.993 0 0 0 17.914.5Zm0 2.414 1.172 1.172-1.293 1.293-1.172-1.172Zm-2.707 2.707 1.172 1.172L5.672 17.5H4.5v-1.172Zm0 0"
    />
  </svg>`;

export const deleteSvg = ({classes = 'w-4 h-24', ariaLabel = '', alt = ''}) =>
  html`<svg
    xmlns="http://www.w3.org/2000/svg"
    alt=${alt}
    aria-label=${ariaLabel}
    class="${classes}"
    viewBox="0 0 24 24"
  >
    <path
      d="M10 2 9 3H4v2h1v15a1.943 1.943 0 0 0 2 2h10a1.937 1.937 0 0 0 2-2V5h1V3h-5l-1-1ZM7 5h10v15H7Zm2 2v11h2V7Zm4 0v11h2V7Zm0 0"
    />
  </svg> `;

export const arrowSvg = ({
  classes = 'w-4 h-4',
  ariaLabel = '',
  alt = '',
  title = '',
}) =>
  html` <svg
    class="${classes}"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    alt=${alt}
    aria-label=${ariaLabel}
  >
    <title>${title}</title>
    <path
      fill-rule="evenodd"
      d="M14 5L12.5 6.5L17.0703 11.0703H3V13.0703H17.0703L12.5 17.6406L14 19.1406L21.0703 12.0703L14 5Z"
      clip-rule="evenodd"
    ></path>
  </svg>`;

export const timeSvg = (classes = 'w-4 h-4') =>
  html` <svg
    class="${classes}"
    alt="${msg('Duration')}"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11,2V4.059a9,9,0,1,0,2,0V2Zm1,4a7,7,0,1,1-7,7A6.985,6.985,0,0,1,12,6ZM11,8v5.414l3.293,3.293,1.414-1.414L13,12.586V8Z"
    />
  </svg>`;

export const fitnessSvg = (classes = 'w-4 h-4') =>
  html` <svg
    class="${classes}"
    alt="${msg('Fitness')}"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M7.5,3A5.5,5.5,0,0,0,2,8.5c0,4.171,4.912,8.213,6.281,9.49C9.858,19.46,12,21.35,12,21.35s2.142-1.889,3.719-3.359C17.088,16.713,22,12.671,22,8.5A5.5,5.5,0,0,0,16.5,3,5.98,5.98,0,0,0,12,5.09,5.98,5.98,0,0,0,7.5,3Zm0,2a3.978,3.978,0,0,1,2.914,1.309L12,8.373l1.586-2.064A4.007,4.007,0,0,1,16.5,5,3.5,3.5,0,0,1,20,8.5c0,2.919-3.769,6.326-5.189,7.609-.177.16-.33.3-.455.416-.751.7-1.639,1.5-2.355,2.146-.716-.642-1.605-1.446-2.355-2.146-.126-.117-.279-.257-.455-.416A25.648,25.648,0,0,1,5.379,12h.955l1.42-1.066,2,3L12.334,12h.938a2,2,0,1,0,0-2h-1.6l-1.42,1.066-2-3L5.666,10H4.3A4.428,4.428,0,0,1,4,8.5,3.5,3.5,0,0,1,7.5,5Z"
      fill="#868e96"
    />
    <rect data-name="Rechteck 3799" width="24" height="24" fill="none" />
  </svg>`;

export const gradeSvg = (classes = 'w-4 h-4') =>
  html` <svg
    class="${classes}"
    alt="${msg('Grade')}"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M2.95 20.91c.42-3.76 1.01-7 7.34-10.16 2.26-1.13 4.05-2.07 1.6-3.76-2.83-1.96 0-5.08 6.68-4.14M15.01 20.9c-1.52-7.14 1.43-6.8 4.69-9.87s-.38-5.08-3.01-5.36"
      style="stroke:#868e96;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;fill:none"
    />
    <path d="M0 0h24v24H0z" style="fill:none" />
  </svg>`;

export const breadcrumbArrowSvg = (classes = 'w-6 h-6') =>
  html` <svg
    class="${classes}"
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      d="M10,5.93,8.5,7.43,13.07,12,8.5,16.57l1.5,1.5L16.07,12Z"
      clip-rule="evenodd"
    ></path>
  </svg>`;

export const spinnerSvg = (classes = 'w-14 h-14 text-chm-gray fill-chm-red') =>
  html` <svg
    role="status"
    class="${classes} inline animate-spin"
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
      fill="currentColor"
    />
    <path
      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
      fill="currentFill"
    />
  </svg>`;

export const configSvg = (classes = 'fill-chm-red h-[24px] w-[24px]') =>
  html`<svg
    id="i_settings"
    xmlns="http://www.w3.org/2000/svg"
    class="${classes}"
    viewBox="0 0 24 24"
  >
    <rect
      id="Rechteck_3768"
      data-name="Rechteck 3768"
      width="24"
      height="24"
      fill="none"
    />
    <path
      id="Pfad_13084"
      data-name="Pfad 13084"
      d="M9.829,14a4,4,0,1,1,4-4,4,4,0,0,1-4,4m0-6a2,2,0,1,0,2,2,2,2,0,0,0-2-2m2.332,12H7.5l-.492-2.522A7.9,7.9,0,0,1,4.76,16.186l-2.423.837L0,12.982l1.944-1.69a7.367,7.367,0,0,1,0-2.582L0,7.023,2.337,2.977l2.423.837A7.906,7.906,0,0,1,7,2.527L7.5,0H12.16l.492,2.522A7.9,7.9,0,0,1,14.9,3.814l2.423-.837,2.338,4.041-1.944,1.69a7.373,7.373,0,0,1,0,2.583l1.939,1.686-2.331,4.046L14.9,16.186a7.974,7.974,0,0,1-2.243,1.286ZM9.145,18h1.368l.39-2,1.043-.4a5.985,5.985,0,0,0,1.683-.96l.865-.709,1.919.663.685-1.188L15.56,12.068l.18-1.1a5.394,5.394,0,0,0,0-1.938V9.018l-.171-1.1L17.1,6.585,16.416,5.4l-1.921.663-.866-.71a5.927,5.927,0,0,0-1.683-.966L10.9,4l-.391-2H9.145l-.39,2-1.043.4a5.917,5.917,0,0,0-1.683.958l-.867.711-1.92-.663L2.557,6.594,4.1,7.931l-.18,1.1a5.394,5.394,0,0,0,0,1.938v.012l.171,1.1L2.557,13.409,3.243,14.6l1.921-.663.866.71a5.927,5.927,0,0,0,1.683.966L8.754,16Z"
      transform="translate(2 2)"
    />
  </svg>`;

export const chevronSvg = (classes = 'w-3 h-3 shrink-0') =>
  html`<svg
    id="chevron-icon"
    class="${classes}"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
    ></path>
  </svg>`;

export const uploadPictureSvg = (
  classes = 'h-[24px] w-[24px]',
  ariaLabel = '',
) =>
  html` <svg
    id="i_upload_picture"
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-label="${ariaLabel}"
  >
    <path
      id="Pfad_13756"
      data-name="Pfad 13756"
      d="M16.03,8.03l-.02,3.1L12.97,9.42l-1.91,1.74L4.98,7.3,2,9.4V5a1,1,0,0,1,.99-1h9.03V2.01H2.98A2.987,2.987,0,0,0,0,4.99V15.98a2.981,2.981,0,0,0,2.98,2.98h12A2.981,2.981,0,0,0,17.95,16L18,8.03H16.03Zm-1.04,8.95H2.98a.994.994,0,0,1-.99-1V11.82L5.03,9.69l6.25,3.97,1.97-1.8,2.74,1.54-.02,2.59a1,1,0,0,1-.99.99ZM20.03,1.97v2h-2V6h-2V3.97H14.04v-2h1.99V0h2V1.97ZM10.06,6.2A1.29,1.29,0,1,1,8.77,7.49,1.291,1.291,0,0,1,10.06,6.2"
      transform="translate(1.985 2.521)"
    />
  </svg>`;

export const uploadFileSvg = (classes = 'h-[24px] w-[24px]', ariaLabel = '') =>
  html`<svg
    class="${classes}"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="${ariaLabel}"
  >
    <g clip-path="url(#clip0_11_10710)">
      <path
        d="M12 2L7 7H11V16H13V7H17L12 2ZM2 9V20C2 21.0931 2.90694 22 4 22H20C21.0931 22 22 21.0931 22 20V9H20V20H4V9H2Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_11_10710">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>`;

export const successSvg = ({
  classes = 'h-[24px] w-[24px]',
  ariaLabel = '',
  alt = '',
}) => html`
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="${classes}"
    aria-label="${ariaLabel}"
    alt="${alt}"
    viewBox="0 0 24 24"
  >
    <circle cx="12" cy="12" r="9" fill="#009139" />
    <path
      fill="#fff"
      d="M12 2a10 10 0 1 0 10 10A10.015 10.015 0 0 0 12 2Zm0 2a8 8 0 1 1-8 8 7.985 7.985 0 0 1 8-8Zm4.293 4.293L10 14.586l-2.293-2.293-1.414 1.414L10 17.414l7.707-7.707Z"
    />
  </svg>
`;

export const noInfoSvg = ({
  classes = 'h-[24px] w-[24px]',
  ariaLabel = '',
  alt = '',
}) => html`
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="${classes}"
    aria-label="${ariaLabel}"
    alt="${alt}"
    viewBox="0 0 24 24"
  >
    <circle cx="12" cy="12" r="9" fill="#868E96" />
    <path
      fill="none"
      stroke="#fff"
      stroke-width="2"
      d="M12 2a10 10 0 1 0 10 10A10.015 10.015 0 0 0 12 2Z"
    />
  </svg>
`;

export const errorSvg = ({
  classes = 'h-[24px] w-[24px]',
  ariaLabel = '',
  alt = '',
}) =>
  html`<svg
    xmlns="http://www.w3.org/2000/svg"
    class="${classes}"
    aria-label="${ariaLabel}"
    alt="${alt}"
    viewBox="0 0 24 24"
  >
    <circle cx="12" cy="12" r="11" fill="#fff" style="stroke-width:1.22222" />
    <path
      fill="#e30613"
      d="M18.364 5.636a9 9 0 1 0 0 12.728 9 9 0 0 0 0-12.728zm-3.818 10.182L12 13.273l-2.546 2.546-1.27-1.274L10.727 12 8.183 9.455l1.27-1.275L12 10.727l2.546-2.546 1.272 1.274-2.545 2.544 2.545 2.546z"
    />
  </svg> `;

export const crossSvg = (
  classes = 'h-[24px] w-[24px] fill-chm-red',
  ariaLabel = '',
) =>
  html` <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-label="${ariaLabel}"
  >
    <path
      d="M4.707,3.293,3.293,4.707,10.586,12,3.293,19.293l1.414,1.414L12,13.414l7.293,7.293,1.414-1.414L13.414,12l7.293-7.293L19.293,3.293,12,10.586Z"
    />
  </svg>`;

export const eyeSvg = (
  classes = 'h-[24px] w-[24px] fill-chm-red',
  ariaLabel = '',
) =>
  html` <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-label="${ariaLabel}"
  >
    <path
      d="M12 4C4 4 1 12 1 12C1 12 4 20 12 20C20 20 23 12 23 12C23 12 20 4 12 4ZM12 6C17.276 6 19.9446 10.2671 20.8086 11.9961C19.9436 13.7131 17.255 18 12 18C6.724 18 4.05541 13.7329 3.19141 12.0039C4.05741 10.2869 6.745 6 12 6ZM12 8C9.791 8 8 9.791 8 12C8 14.209 9.791 16 12 16C14.209 16 16 14.209 16 12C16 9.791 14.209 8 12 8ZM12 10C13.105 10 14 10.895 14 12C14 13.105 13.105 14 12 14C10.895 14 10 13.105 10 12C10 10.895 10.895 10 12 10Z"
    />
  </svg>`;

export const strikedEyeSvg = (
  classes = 'h-[24px] w-[24px] fill-chm-red',
  ariaLabel = '',
) =>
  html` <svg
    class="${classes}"
    viewBox="0 2 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="${ariaLabel}"
  >
    <path
      d="M3.70703 4L2.29297 5.41406L5.06836 8.18945C4.52925 8.62894 4.04856 9.09704 3.61914 9.56641C1.78914 11.6064 1 13.707 1 13.707C1 13.707 4 21.707 12 21.707C13.2 21.707 14.2895 21.5268 15.2695 21.2168C15.9886 20.9971 16.6486 20.7086 17.248 20.3691L20.293 23.4141L21.707 22L3.70703 4ZM12 5.70703C10.8 5.70703 9.71047 5.88727 8.73047 6.19727L10.3906 7.85742C10.8906 7.75742 11.43 7.70703 12 7.70703C17.28 7.70703 19.9405 11.977 20.8105 13.707C20.4805 14.367 19.8709 15.4077 18.9609 16.4277L20.3809 17.8477C22.2109 15.8077 23 13.707 23 13.707C23 13.707 20 5.70703 12 5.70703ZM6.48828 9.60938L8.55859 11.6797C8.19945 12.2792 8 12.968 8 13.707C8 13.787 7.99977 13.877 8.00977 13.957C8.12977 15.967 9.74 17.5773 11.75 17.6973C11.83 17.7073 11.92 17.707 12 17.707C12.7391 17.707 13.4279 17.5076 14.0273 17.1484L15.7578 18.8789C15.1084 19.1884 14.3885 19.4268 13.6094 19.5566C13.0994 19.6566 12.57 19.707 12 19.707C6.72 19.707 4.05945 15.437 3.18945 13.707C3.51945 13.047 4.11906 12.0063 5.03906 10.9863C5.44851 10.517 5.93918 10.0489 6.48828 9.60938ZM12.25 9.7168L15.9902 13.457C15.8702 11.447 14.26 9.8368 12.25 9.7168ZM10.0703 13.1914L12.5156 15.6367C12.3565 15.6859 12.1787 15.707 12 15.707C10.9 15.707 10 14.807 10 13.707C10 13.5284 10.0211 13.3505 10.0703 13.1914Z"
    />
  </svg>`;

export const shareSvg = (classes = 'fill-chm-red', ariaLabel = '') =>
  html` <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    aria-label="${ariaLabel}"
    class="${classes}"
  >
    <path
      d="M18,2a3.015,3.015,0,0,0-3,3,2.956,2.956,0,0,0,.057.559L7.922,9.721A2.969,2.969,0,0,0,6,9a3,3,0,0,0,0,6,2.969,2.969,0,0,0,1.922-.721l7.135,4.16A2.956,2.956,0,0,0,15,19a3,3,0,1,0,3-3,2.968,2.968,0,0,0-1.922.723L8.943,12.559a2.783,2.783,0,0,0,0-1.117l7.135-4.162A2.969,2.969,0,0,0,18,8a3,3,0,0,0,0-6Zm0,2a1,1,0,1,1-1,1A.985.985,0,0,1,18,4ZM6,11a1,1,0,1,1-1,1A.985.985,0,0,1,6,11Zm12,7a1,1,0,1,1-1,1A.985.985,0,0,1,18,18Z"
    />
  </svg>`;

export const copySvg = (classes = 'fill-chm-red', ariaLabel = '') =>
  html` <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    aria-label="${ariaLabel}"
    class="${classes}"
  >
    <path
      d="M18,4H6A2,2,0,0,0,4,6V18a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2m0,14H6V6H18ZM2,2H16V0H2A2,2,0,0,0,0,2V16H2Z"
      transform="translate(2 2)"
    />
  </svg>`;

export const emailSvg = (classes = 'w-4 h-4') => html`
  <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M12 2a10 10 0 0 0 0 20h5v-2h-5a8 8 0 1 1 8-8v1.5a1.5 1.5 0 0 1-3 0V12a5.039 5.039 0 1 0-1.4 3.447A3.495 3.495 0 0 0 22 13.5V12A10.015 10.015 0 0 0 12 2Zm0 7a3 3 0 1 1-3 3 2.985 2.985 0 0 1 3-3Z"
    />
  </svg>
`;

export const shareOrPrintSvg = (classes = 'w-4 h-4') => html`
  <svg class="${classes}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      d="M11.2,15.771a.816.816,0,1,1,.815-.816.817.817,0,0,1-.815.816M21.044,12.4a2.242,2.242,0,0,1-4.407-.827L11.846,8.777a2.173,2.173,0,0,1-1.341.468,2.2,2.2,0,1,1,0-4.4,2.176,2.176,0,0,1,1.342.473l4.79-2.794a2.373,2.373,0,0,1-.026-.322,2.218,2.218,0,0,1,2.2-2.2h0a2.2,2.2,0,1,1,0,4.4,2.175,2.175,0,0,1-1.341-.468L12.679,6.722a1.977,1.977,0,0,1,0,.648l4.791,2.8A2.242,2.242,0,0,1,21.044,12.4M18.81,11.321h-.02a.556.556,0,0,0-.392.171.55.55,0,0,0-.156.4.569.569,0,1,0,.568-.567m0-8.553a.569.569,0,1,0,0-1.137h-.02a.557.557,0,0,0-.548.567.569.569,0,0,0,.568.571m-8.3,3.708h-.021a.558.558,0,0,0-.548.567.569.569,0,1,0,.569-.567m3.584,7.787v4.969H11.321V22H2.769V19.231H0V14.263a2.211,2.211,0,0,1,2.2-2.2h.569V9.294H6.912v1.631H4.4v1.137h7.489a2.213,2.213,0,0,1,2.2,2.2m-2.769,1.953V17.6h1.137V14.263a.569.569,0,0,0-.57-.569H2.2a.569.569,0,0,0-.57.569V17.6H2.769V16.216ZM9.69,17.847H4.4v2.521H9.69Z"
      transform="translate(1.454 1)">
  </svg>`;

export const plusSvg = (classes = 'w-4 h-4') => html`
  <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path d="M11 3v8H3v2h8v8h2v-8h8v-2h-8V3Z" />
  </svg>
`;

export const minusSvg = (classes = 'w-4 h-4') => html`
  <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path d="M3 11v2h18v-2Z" />
  </svg>
`;

export const targetSvg = (classes = 'w-4 h-4') => html`
  <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      fill="none"
      stroke-width="2"
      d="M21.045 2.523a.25.25 0 0 1 .33.33l-8.181 18.25a.5.5 0 0 1-.953-.147l-.969-8.331-8.331-.969a.5.5 0 0 1-.147-.953Z"
    />
  </svg>
`;

export const swissSvg = (classes = 'w-4 h-4', fill = '#fff') => html`
  <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 92 92"
  >
    <path
      fill="${fill}"
      d="M49.358 27.243c-.017-.109-.028-.221-.05-.33-.016-.079-.041-.154-.06-.232-.038-.16-.074-.321-.123-.477a6.673 6.673 0 0 0-.211-.576c-.014-.034-.024-.07-.038-.1-.063-.149-.137-.291-.21-.435-.035-.07-.065-.144-.1-.213-.064-.117-.137-.227-.207-.34-.055-.09-.106-.183-.164-.27-.07-.1-.15-.2-.225-.3s-.13-.184-.2-.27-.126-.138-.188-.207c-.1-.108-.189-.218-.29-.32-.055-.054-.116-.1-.173-.156-.116-.109-.23-.22-.353-.321-.084-.07-.175-.131-.262-.2-.1-.077-.2-.158-.308-.23s-.19-.114-.285-.172-.214-.138-.326-.2-.216-.104-.327-.156-.21-.109-.32-.155c-.133-.057-.271-.1-.408-.15-.091-.033-.179-.071-.272-.099-.121-.034-.246-.064-.369-.095s-.231-.064-.342-.088-.258-.04-.388-.06-.229-.04-.346-.052c-.135-.014-.274-.015-.411-.021-.117-.006-.231-.018-.349-.018a7.408 7.408 0 0 0-7.4 7.406c0 4.194 4.658 10.387 6.581 12.774a1 1 0 0 0 .244.2 1.05 1.05 0 0 0 .184.1 1.032 1.032 0 0 0 .392.077 1.139 1.139 0 0 0 .2-.019 1.064 1.064 0 0 0 .19-.057 1 1 0 0 0 .188-.1 1.02 1.02 0 0 0 .243-.2c1.924-2.388 6.583-8.581 6.583-12.775a7.309 7.309 0 0 0-.1-1.163m-7.309 3.808a2.646 2.646 0 1 1 1.871-.775 2.645 2.645 0 0 1-1.871.775m27.99 6.879v14.106h-8.707L56.3 64.617l-6.042-10.072-3.016 7.573h-13.1L32.218 52.5l-10.26 2.556 10.946-21.9a29.463 29.463 0 0 0 2.3 4.429l-5.718 11.432 5.86-1.465 2.1 10.531h7.052L49.537 45.5l6.043 10.074 3.02-7.566h7.416v-6.042h-7.618L56.379 33.9h-5.481a16.192 16.192 0 0 0 1.26-4.028h7.372l2.012 8.058Z"
    />
  </svg>
`;

export const betaFeedbackSvg = (classes = '', fill = '#fff') => html`
  <svg
    class="${classes}"
    viewBox="0 0 30 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.99886 0C1.3607 0 0 1.41181 0 3.1115V21.7884C0 23.492 1.3607 24.9039 2.99886 24.9039H24.0023L30 31.1269L29.9848 3.1115C29.9848 1.40787 28.6203 0 26.9821 0H2.99886ZM2.99886 3.1115H26.9821L26.9973 23.6064L25.2452 21.7884H2.99886V3.1115ZM7.49905 7.78073V10.8962H22.4971V7.78073H7.49905ZM7.49905 14.0077V17.1192H17.997V14.0077H7.49905Z"
      fill="${fill}"
    />
    <path
      d="M25.6786 39.9951C25.2213 39.9951 24.8137 39.9128 24.4559 39.7482C24.0982 39.5803 23.815 39.3334 23.6066 39.0074C23.4013 38.6781 23.2986 38.2681 23.2986 37.7775C23.2986 37.3643 23.3717 37.0173 23.5179 36.7364C23.6641 36.4556 23.8633 36.2296 24.1153 36.0586C24.3673 35.8875 24.6535 35.7583 24.9739 35.6712C25.2975 35.584 25.6366 35.5227 25.9913 35.4872C26.4082 35.442 26.7442 35.4 26.9993 35.3613C27.2544 35.3193 27.4396 35.258 27.5547 35.1773C27.6698 35.0966 27.7273 34.9772 27.7273 34.819V34.7899C27.7273 34.4833 27.634 34.246 27.4473 34.0782C27.2638 33.9103 27.0024 33.8264 26.6633 33.8264C26.3055 33.8264 26.0209 33.9087 25.8093 34.0733C25.5977 34.2347 25.4577 34.4381 25.3893 34.6834L23.5506 34.5285C23.6439 34.0765 23.8275 33.686 24.1013 33.3567C24.375 33.0242 24.7282 32.7692 25.1606 32.5916C25.5962 32.4109 26.1002 32.3205 26.6726 32.3205C27.0709 32.3205 27.452 32.3689 27.816 32.4658C28.1831 32.5626 28.5082 32.7127 28.7914 32.9161C29.0776 33.1194 29.3031 33.3809 29.468 33.7005C29.6329 34.0168 29.7154 34.3961 29.7154 34.8384V39.8547H27.83V38.8234H27.774C27.6589 39.0558 27.5049 39.2608 27.312 39.4383C27.1191 39.6126 26.8873 39.7498 26.6166 39.8499C26.346 39.9467 26.0333 39.9951 25.6786 39.9951ZM26.248 38.5716C26.5404 38.5716 26.7987 38.5119 27.0227 38.3924C27.2467 38.2698 27.4224 38.1051 27.55 37.8985C27.6776 37.6919 27.7413 37.4579 27.7413 37.1964V36.4072C27.6791 36.4491 27.5936 36.4879 27.4847 36.5234C27.3789 36.5557 27.2591 36.5863 27.1253 36.6154C26.9915 36.6412 26.8578 36.6654 26.724 36.688C26.5902 36.7074 26.4689 36.7251 26.36 36.7413C26.1266 36.7768 25.9229 36.8333 25.7486 36.9108C25.5744 36.9882 25.4391 37.0931 25.3426 37.2255C25.2462 37.3546 25.198 37.516 25.198 37.7097C25.198 37.9905 25.296 38.2052 25.492 38.3537C25.6911 38.4989 25.9431 38.5716 26.248 38.5716Z"
      fill="${fill}"
    />
    <path
      d="M21.6826 32.4173V33.9668H17.3658V32.4173H21.6826ZM18.3459 30.6354H20.3339V37.5692C20.3339 37.7597 20.3619 37.9082 20.4179 38.0147C20.4739 38.118 20.5517 38.1906 20.6512 38.2326C20.7539 38.2746 20.8721 38.2956 21.0059 38.2956C21.0992 38.2956 21.1926 38.2875 21.2859 38.2713C21.3793 38.252 21.4508 38.2374 21.5006 38.2278L21.8133 39.7627C21.7137 39.795 21.5737 39.8321 21.3933 39.8741C21.2128 39.9193 20.9935 39.9467 20.7352 39.9564C20.2561 39.9757 19.8361 39.9096 19.4752 39.7579C19.1174 39.6061 18.839 39.3705 18.6399 39.0509C18.4408 38.7313 18.3428 38.3278 18.3459 37.8404V30.6354Z"
      fill="${fill}"
    />
    <path
      d="M12.6254 40C11.8881 40 11.2534 39.845 10.7214 39.5351C10.1925 39.222 9.78491 38.7798 9.49868 38.2084C9.21245 37.6338 9.06934 36.9543 9.06934 36.1699C9.06934 35.4049 9.21245 34.7334 9.49868 34.1556C9.78491 33.5778 10.1878 33.1275 10.7074 32.8047C11.23 32.4819 11.8429 32.3205 12.5461 32.3205C13.019 32.3205 13.4592 32.3996 13.8668 32.5578C14.2774 32.7127 14.6352 32.9467 14.9401 33.2599C15.2481 33.573 15.4877 33.9668 15.6588 34.4413C15.8299 34.9126 15.9155 35.4646 15.9155 36.0973V36.6638H9.86268V35.3855H14.0441C14.0441 35.0885 13.9819 34.8254 13.8574 34.5963C13.733 34.3671 13.5603 34.1879 13.3394 34.0588C13.1216 33.9264 12.8681 33.8603 12.5787 33.8603C12.277 33.8603 12.0094 33.9329 11.7761 34.0782C11.5458 34.2202 11.3654 34.4123 11.2347 34.6544C11.104 34.8932 11.0372 35.1595 11.034 35.4533V36.6687C11.034 37.0366 11.0994 37.3546 11.23 37.6225C11.3638 37.8905 11.552 38.0971 11.7947 38.2423C12.0374 38.3876 12.3252 38.4602 12.6581 38.4602C12.879 38.4602 13.0812 38.4279 13.2647 38.3634C13.4483 38.2988 13.6054 38.202 13.7361 38.0728C13.8668 37.9437 13.9663 37.7856 14.0348 37.5983L15.8735 37.7242C15.7801 38.1826 15.5888 38.5829 15.2995 38.925C15.0132 39.264 14.643 39.5287 14.1888 39.7191C13.7376 39.9064 13.2165 40 12.6254 40Z"
      fill="${fill}"
    />
    <path
      d="M0.0505371 39.8547V29.9382H3.87728C4.5804 29.9382 5.16686 30.0463 5.63664 30.2626C6.10643 30.4789 6.45955 30.7791 6.696 31.1632C6.93244 31.5441 7.05067 31.9831 7.05067 32.4802C7.05067 32.8676 6.976 33.2082 6.82666 33.5019C6.67733 33.7924 6.47199 34.0313 6.21065 34.2185C5.95243 34.4025 5.65687 34.5333 5.32397 34.6107V34.7076C5.68798 34.7237 6.02865 34.8303 6.34599 35.0272C6.66644 35.2241 6.92622 35.5001 7.12534 35.8552C7.32445 36.207 7.42401 36.6267 7.42401 37.1141C7.42401 37.6403 7.29801 38.1099 7.046 38.5231C6.79711 38.9331 6.42844 39.2575 5.93998 39.4964C5.45153 39.7352 4.84952 39.8547 4.13395 39.8547H0.0505371ZM2.07124 38.1406H3.71861C4.28173 38.1406 4.6924 38.0292 4.95063 37.8065C5.20886 37.5805 5.33797 37.2803 5.33797 36.9059C5.33797 36.6315 5.27419 36.3894 5.14663 36.1796C5.01908 35.9697 4.83707 35.8051 4.60062 35.6857C4.36729 35.5662 4.08884 35.5065 3.76527 35.5065H2.07124V38.1406ZM2.07124 34.0878H3.56927C3.84616 34.0878 4.09195 34.0378 4.30662 33.9377C4.5244 33.8344 4.69551 33.6891 4.81996 33.5019C4.94752 33.3147 5.0113 33.0903 5.0113 32.8289C5.0113 32.4706 4.88841 32.1817 4.64262 31.9621C4.39995 31.7426 4.05461 31.6329 3.6066 31.6329H2.07124V34.0878Z"
      fill="${fill}"
    />
  </svg>
`;

export const openMapactionSvg = (classes = 'w-4 h-4', fill = '#fff') => html`
  <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 92 92"
  >
    <path
      fill="${fill}"
      d="M46 26a4 4 0 1 1-4 4 4 4 0 0 1 4-4m0 14a4 4 0 1 1-4 4 4 4 0 0 1 4-4m0 14a4 4 0 1 1-4 4 4 4 0 0 1 4-4"
    />
  </svg>
`;

// Same as openMapactionSvg, but with less margin
export const tourListSortSvg = (classes = 'w-4 h-4', fill = '#fff') => html`
  <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
  >
    <path
      fill="${fill}"
      d="m 24,6 a 4,4 0 1 1 -4,4 4,4 0 0 1 4,-4 m 0,14 a 4,4 0 1 1 -4,4 4,4 0 0 1 4,-4 m 0,14 a 4,4 0 1 1 -4,4 4,4 0 0 1 4,-4"
    />
  </svg>
`;

export const linkSvg = (classes = '', ariaLabel = '') => html`
  <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    aria-label="${ariaLabel}"
  >
    <path
      d="M10.0128 0L8.80399 1.20884L9.73913 2.14398L4.43621 6.39772L3.17035 5.13186L1.96151 6.3407L5.21169 9.59088L0 14.8026V16H1.19743L6.40912 10.7883L9.6593 14.0385L10.8681 12.8297L9.6707 11.6322L13.8674 6.27227L14.7912 7.19601L16 5.98717L10.0128 0ZM10.948 3.37562L12.6472 5.07484L8.53029 10.3321L5.74768 7.54954L10.9366 3.37562H10.948Z"
    />
  </svg>
`;

export const closeMapactionSvg = (classes = 'w-4 h-4', fill = '#fff') => html`
  <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 92 92"
  >
    <path
      fill="${fill}"
      d="M30.923 26 28 28.923 43.077 44 28 59.077 30.923 62 46 46.923 61.077 62 64 59.077 48.923 44 64 28.923 61.077 26 46 41.077Z"
    />
  </svg>
`;

export const sharePrintSvg = (classes = 'w-4 h-4') => html`
  <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M19 21.707H5a3 3 0 0 1-3-3v-11h2v11a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-2h2v2a3 3 0 0 1-3 3M16.707 2l-1.414 1.414 2.293 2.293H17a11 11 0 0 0-11 11v1h2v-1a9.011 9.011 0 0 1 9-9h.586L15.293 10l1.414 1.414 4.707-4.707Z"
    />
  </svg>
`;

export const layersSvg = (selected = false, fill = '#fff') =>
  html` <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <g transform="translate(2.5 3)">
      <path
        d="M11.513,1.883,1.764,6.756l1.7.852L6.526,9.137,1.764,11.518,6.526,13.9,1.764,16.28l9.749,4.874,9.749-4.874L16.5,13.9l4.762-2.381L16.5,9.137l4.762-2.381Zm0,2.128L17,6.756,11.513,9.5,6.023,6.756ZM8.655,10.2l2.857,1.429L14.37,10.2,17,11.518l-5.489,2.746L6.023,11.518Zm0,4.762,2.857,1.429,2.857-1.429L17,16.28l-5.489,2.746L6.023,16.28Z"
        fill="${fill}"
      />
      ${selected
        ? svg`
        <path d="M14.6,5.5A2.5,2.5,0,1,1,12.1,3a2.5,2.5,0,0,1,2.5,2.5" transform="translate(7 -5)" fill="#fff"/>`
        : ''}
    </g>
  </svg>`;

export function resetSvg() {
  return html`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
    >
      <rect width="24" height="24" fill="none" />
      <path
        d="M12,2A10,10,0,1,0,22,12H20a8.022,8.022,0,1,1-2.35-5.65L15,9h7V2L19.061,4.939A9.944,9.944,0,0,0,12,2Z"
        fill="#ad091d"
      />
    </svg>
  `;
}

export function phoneSvg(classes = 'w-4 h-4') {
  return html`
    <svg
      class="${classes}"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M20,3A16.955,16.955,0,0,0,3,20a.992.992,0,0,0,1,1H7.51a1,1,0,0,0,1-.977,15.077,15.077,0,0,1,.14-1.924,14.67,14.67,0,0,1,.425-1.653,1.017,1.017,0,0,0-.253-1.019L6.62,13.21a15.618,15.618,0,0,1,6.59-6.59l2.218,2.2a1.018,1.018,0,0,0,1.019.253A14.83,14.83,0,0,1,18.1,8.652a15.063,15.063,0,0,1,1.924-.14,1,1,0,0,0,.976-1V4A.992.992,0,0,0,20,3ZM7,16.426c-.142.5-.247.946-.314,1.326A11.139,11.139,0,0,0,6.552,19H5.034a15.066,15.066,0,0,1,.758-3.785ZM19,6.552a11.113,11.113,0,0,0-1.248.131c-.38.067-.824.172-1.326.314l-1.211-1.2A15.076,15.076,0,0,1,19,5.034Z"
      />
      <rect width="24" height="24" fill="none" />
    </svg>
  `;
}

export function downloadSvg(classes = 'fill-chm-red', stroke = '') {
  return html`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      class="${classes}"
    >
      <rect width="24" height="24" fill="none" />
      <path
        class="${stroke}"
        d="M6,2A2.013,2.013,0,0,0,4,4V20a2.013,2.013,0,0,0,2,2H16.172l-1.586-1.586L14.172,20H6V4h7V9h5v3h2V8L14,2ZM18,14v4H15l4,4,4-4H20V14Z"
        transform="translate(-1)"
      />
    </svg>
  `;
}

export function navFirstSvg(fill = 'fill-chm-red', stroke = 'stroke-chm-red') {
  return html`
    <svg
      class="mx-auto my-[8px] lg:my-[16px]"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M26.273 24h-24V0h24z" />
      <path
        class="${fill}"
        fill="none"
        d="m12.273 19 1.5-1.5-4.57-4.57h14.07v-2H9.203l4.57-4.571-1.5-1.5-7.07 7.07z"
      />
      <path
        class="${stroke}"
        fill="none"
        stroke="none"
        stroke-width="2"
        d="M1.737 3v18"
      />
    </svg>
  `;
}

export function navNextSvg(fill = 'fill-chm-red') {
  return html`
    <svg
      class="mx-auto my-[8px] lg:my-[16px]"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      alt=""
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        class="${fill}"
        fill="none"
        d="m14 5-1.5 1.5 4.57 4.57H3v2h14.07l-4.57 4.57 1.5 1.5 7.07-7.07Z"
      />
    </svg>
  `;
}

export function navPreviousSvg(fill = 'fill-chm-red') {
  return html`
    <svg
      class="mx-auto my-[8px] lg:my-[16px]"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      alt=""
      height="24"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M24 24H0V0h24z" />
      <path
        class="${fill}"
        fill="none"
        d="m10 19 1.5-1.5-4.57-4.57H21v-2H6.93l4.57-4.57-1.5-1.5-7.07 7.07Z"
      />
    </svg>
  `;
}

export function image0(src: string): string {
  if (!src) return '';
  return src.replace(
    'webdb0-bucket-1qo88wr12euqw.s3.amazonaws.com',
    'images.schweizmobil.ch',
  );
}

export function getPhoto(data: Place | Sightseeing) {
  let photo = data.photoBig || data.photo || data.photoSmall;
  if (photo) photo = image0(photo);
  return photo;
}

export function getPhotoAbstract(data: Place | Sightseeing) {
  const photoAbstract = data.photoAbstract;
  return photoAbstract;
}

export function divider(
  width: string,
  height: string,
  padding: string,
  color: string,
  classes?: string,
) {
  return html` <div
    class=${ifDefined(classes)}
    style="${styleMap({padding: padding})}"
  >
    <div style="${styleMap({backgroundColor: color, height, width})}"></div>
  </div>`;
}

export function closeHeader(
  title: string,
  close: () => void,
  scrolled?: boolean,
) {
  return html` <div
    class="group ${classMap({
      'shadow-popup': scrolled,
    })} sticky top-0 flex h-[40px] items-center justify-between bg-white py-[8px] pl-[16px] pr-[8px]"
  >
    <span data-cy="poi-header-title" class="body-links-and-text">${title}</span>
    <div class="cursor-pointer" @click="${close}">
      ${crossSvg(
        'h-[24px] w-[24px] fill-chm-red mouse:group-hover:fill-chm-focus-red',
      )}
    </div>
  </div>`;
}

export function overlayHeader(options?: {
  title?: TemplateResult<1>;
  scrolled?: boolean;
  closeText?: boolean;
  classFont?: string;
  onClose?: () => void;
  maxScreenSizeCode?: ScreenSizeCode;
}) {
  const closeText = options?.closeText !== undefined ? options.closeText : true;
  const classFont =
    options?.classFont !== undefined
      ? options.classFont
      : 'text-overlay-header md:text-md-overlay-header lg:text-lg-overlay-header';
  return html` <div
    class="${classMap({
      flex: !options?.maxScreenSizeCode || !!options?.maxScreenSizeCode,
      [`${options?.maxScreenSizeCode}:hidden`]: !!options?.maxScreenSizeCode,
      'shadow-popup': options?.scrolled,
    })} sticky top-0 z-50 bg-inherit px-[20px] pb-[16px] pt-[12px] md:pt-[20px] xl:pb-[20px]"
  >
    <div class="${classFont} flex flex-1">${options?.title || ''}</div>
    <button
      data-cy="close"
      class="group ${classFont} sticky top-0 flex cursor-pointer items-center gap-x-icon-text-gap mouse:hover:text-chm-focus-red"
      @click="${(event: Event) => {
        event.stopPropagation();
        if (options?.onClose) {
          options.onClose();
        }
        modal$.next(undefined);
      }}"
    >
      ${closeText ? msg('Close') : ''}
      ${crossSvg(
        'h-[24px] w-[24px] fill-chm-red mouse:group-hover:fill-chm-focus-red',
      )}
    </button>
  </div>`;
}

export function arrivalLinks(stationName: string, card = true) {
  const arrivalUrl = `${SBB_URL_BY_LANG[getLanguageCode()]}?nach=${stationName}`;
  const returnUrl = `${SBB_URL_BY_LANG[getLanguageCode()]}?von=${stationName}`;
  return html` <div
      data-cy="station-arrival-url"
      class="${classMap({
        'link-with-icon-in-context': card,
        'link-with-icon-alone': !card,
      })}"
    >
      <a
        href="${arrivalUrl}"
        target="_blank"
        class="group flex items-center gap-x-[10px] overflow-hidden mouse:hover:text-chm-focus-red"
      >
        <span class="group"
          >${arrowSvg({
            classes:
              'fill-chm-red mouse:group-hover:fill-chm-focus-red -rotate-45 w-[24px] h-[24px]',
          })}</span
        >
        <span>${msg('Arrival')}</span>
        <span class="screen-reader-only">${msg('(opens in a new tab)')}</span>
      </a>
    </div>
    <div
      data-cy="station-return-url"
      class="${classMap({
        'link-with-icon-in-context': card,
        'link-with-icon-alone': !card,
      })}"
    >
      <a
        href="${returnUrl}"
        target="_blank"
        class="group flex items-center gap-x-[10px] overflow-hidden mouse:hover:text-chm-focus-red"
      >
        <span
          >${arrowSvg({
            classes:
              'fill-chm-red mouse:group-hover:fill-chm-focus-red -rotate-45 w-[24px] h-[24px]',
          })}</span
        >
        <span>${msg('Return')}</span>
        <span class="screen-reader-only">${msg('(opens in a new tab)')}</span>
      </a>
    </div>`;
}

export const instagramSvg = (classes = 'w-4 h-4', ariaLabel = '') => html`
  <svg
    class="${classes}"
    role="img"
    alt="Instagram"
    aria-labelledby="instagramTitle"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="${ariaLabel}"
  >
    <title id="instagramTitle">Instagram</title>
    <rect
      id="Rechteck_4160"
      data-name="Rechteck 4160"
      width="24"
      height="24"
      fill="none"
    />
    <path
      id="icons8-instagram"
      d="M8,3A5.006,5.006,0,0,0,3,8v8a5.006,5.006,0,0,0,5,5h8a5.006,5.006,0,0,0,5-5V8a5.006,5.006,0,0,0-5-5ZM8,5h8a3,3,0,0,1,3,3v8a3,3,0,0,1-3,3H8a3,3,0,0,1-3-3V8A3,3,0,0,1,8,5Zm9,1a1,1,0,1,0,1,1A1,1,0,0,0,17,6ZM12,7a5,5,0,1,0,5,5A5.006,5.006,0,0,0,12,7Zm0,2a3,3,0,1,1-3,3A3,3,0,0,1,12,9Z"
    />
  </svg>
`;

export const facebookSvg = (classes = 'w-4 h-4', ariaLabel = '') => html`
  <svg
    class="${classes}"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    alt="Facebook"
    aria-label="${ariaLabel}"
  >
    <title>Facebook</title>
    <path
      d="M12 3C7.03832 3 3 7.03832 3 12C3 16.9617 7.03832 21 12 21C16.9617 21 21 16.9617 21 12C21 7.03832 16.9617 3 12 3ZM12 4.5C16.151 4.5 19.5 7.84898 19.5 12C19.5 15.7705 16.7338 18.871 13.1162 19.4092V14.187H15.252L15.5874 12.0176H13.1162V10.8325C13.1162 9.93177 13.4121 9.13184 14.2544 9.13184H15.6079V7.23926C15.3702 7.20701 14.867 7.13672 13.916 7.13672C11.93 7.13672 10.7666 8.18521 10.7666 10.5747V12.0176H8.72461V14.187H10.7666V19.3901C7.20664 18.803 4.5 15.7298 4.5 12C4.5 7.84898 7.84898 4.5 12 4.5Z"
    />
  </svg>
`;

export const linkedInSvg = (classes = 'w-4 h-4', ariaLabel = '') => html`
  <svg
    class="${classes}"
    role="img"
    alt="Linkedin"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="${ariaLabel}"
  >
    <title>LinkedIn</title>
    <rect
      id="Rechteck_4161"
      data-name="Rechteck 4161"
      width="24"
      height="24"
      fill="none"
    />
    <path
      id="icons8-linkedin"
      d="M5,3A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5a2,2,0,0,0-2-2ZM5,5H19V19H5ZM7.779,6.316a1.223,1.223,0,0,0-1.371,1.2,1.194,1.194,0,0,0,1.285,1.2,1.222,1.222,0,0,0,1.371-1.2A1.2,1.2,0,0,0,7.779,6.316ZM6.477,10v7H9V10Zm4.605,0v7h2.523V13.174c0-1.139.813-1.3,1.057-1.3s.9.245.9,1.3V17H18V13.174c0-2.2-.976-3.174-2.2-3.174a2.282,2.282,0,0,0-2.2.977V10Z"
    />
  </svg>
`;

export const youtubeSvg = (classes = 'w-4 h-4', ariaLabel = '') => html`
  <svg
    class="${classes}"
    role="img"
    alt="Youtube"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="${ariaLabel}"
  >
    <title>YouTube</title>
    <rect
      id="Rechteck_4162"
      data-name="Rechteck 4162"
      width="24"
      height="24"
      fill="none"
    />
    <path
      id="icons8-youtube"
      d="M12,4s-6.254,0-7.814.418A2.5,2.5,0,0,0,2.418,6.186,35.647,35.647,0,0,0,2,12a35.647,35.647,0,0,0,.418,5.814,2.5,2.5,0,0,0,1.768,1.768C5.746,20,12,20,12,20s6.254,0,7.814-.418a2.5,2.5,0,0,0,1.768-1.768A35.647,35.647,0,0,0,22,12a35.647,35.647,0,0,0-.418-5.814,2.5,2.5,0,0,0-1.768-1.768C18.254,4,12,4,12,4Zm0,2a53.985,53.985,0,0,1,7.3.35.508.508,0,0,1,.354.354A32.03,32.03,0,0,1,20,12a32.039,32.039,0,0,1-.35,5.3.508.508,0,0,1-.354.354A53.975,53.975,0,0,1,12,18a54.007,54.007,0,0,1-7.3-.35A.508.508,0,0,1,4.35,17.3,32.031,32.031,0,0,1,4,12a32.073,32.073,0,0,1,.35-5.3A.505.505,0,0,1,4.7,6.35,53.975,53.975,0,0,1,12,6ZM10,8.535v6.93L16,12Z"
    />
  </svg>
`;

export const goToFooterSvg = (classes = 'w-4 h-4') => html`
  <svg
    class="${classes}"
    viewBox="0 0 52 52"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 17.425,20.1435 -1.425,1.413 10.208,10.3 9.792,-10.313 -1.454,-1.385 -8.369,8.815 z"
    />
  </svg>
`;

export const showOnMapSvg = (classes = 'w-4 h-4') => html`
  <svg
    viewBox="0 0 24 24"
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    alt="Show on map"
  >
    <path
      d="M17.211 2.02295C16.2062 2.02321 15.2426 2.4225 14.5321 3.13301C13.8216 3.84353 13.4223 4.80713 13.422 5.81195C13.422 8.52095 17.211 12.4439 17.211 12.4439C17.211 12.4439 21 8.52295 21 5.81195C20.9997 4.80713 20.6005 3.84353 19.8899 3.13301C19.1794 2.4225 18.2158 2.02321 17.211 2.02295ZM17.211 4.46095C17.4781 4.46273 17.7386 4.54355 17.9598 4.69323C18.1809 4.84292 18.3528 5.05474 18.4538 5.302C18.5547 5.54925 18.5801 5.82086 18.5269 6.08255C18.4736 6.34425 18.344 6.5843 18.1545 6.77245C17.965 6.96059 17.7239 7.08838 17.4619 7.13971C17.1998 7.19104 16.9284 7.1636 16.6819 7.06086C16.4354 6.95812 16.2248 6.78468 16.0768 6.56241C15.9287 6.34014 15.8498 6.07901 15.85 5.81195C15.8519 5.45248 15.9962 5.10841 16.2513 4.85516C16.5064 4.60191 16.8515 4.46015 17.211 4.46095ZM8.65001 4.80595L3.59701 6.82895C3.42028 6.89796 3.26848 7.01867 3.16144 7.1753C3.0544 7.33194 2.99709 7.51723 2.99701 7.70695V20.9699C2.9968 21.125 3.03459 21.2778 3.10707 21.4148C3.17955 21.5519 3.28452 21.6692 3.41279 21.7563C3.54106 21.8434 3.68873 21.8978 3.84287 21.9146C3.99702 21.9315 4.15294 21.9103 4.29701 21.8529L8.71301 20.0819L14.397 21.9769L19.455 19.959C19.6316 19.8882 19.783 19.7664 19.8899 19.6091C19.9968 19.4518 20.0543 19.2661 20.055 19.0759V12.0539C19.399 12.8829 18.821 13.5099 18.575 13.7609L18.161 14.1899V18.4329L15.319 19.5729V13.1829C14.6311 12.4174 13.9979 11.6044 13.424 10.7499V19.6559L9.63201 18.3949V7.12295L11.932 7.89295C11.6826 7.22636 11.5474 6.5225 11.532 5.81095C11.532 5.79595 11.532 5.77595 11.532 5.76195L8.65001 4.80595ZM7.73701 7.21395V18.4339L4.89501 19.5739V8.34895L7.73701 7.21395Z"
    />
  </svg>
`;

export const rank1Svg = (classes = 'h-4') => html`
  <svg
    class="${classes} w-auto"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#161615"
      d="M11.813 0 9.167 8.9H0l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L24 8.9h-9.461z"
      data-name="Pfad 13795"
    />
  </svg>
`;
export const rank2Svg = (classes = 'h-4') => html`
  <svg
    class="${classes} w-auto"
    viewBox="0 0 49 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#161615"
      d="m36.813 0-2.646 8.9H25l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L49 8.9h-9.461zm-25 0L9.167 8.9H0l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L24 8.9h-9.461z"
      data-name="Pfad 13795"
    />
  </svg>
`;
export const rank3Svg = (classes = 'h-4') => html`
  <svg
    class="${classes} w-auto"
    viewBox="0 0 74 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#161615"
      d="m61.813 0-2.646 8.9H50l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L74 8.9h-9.461zm-25 0-2.646 8.9H25l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L49 8.9h-9.461zm-25 0L9.167 8.9H0l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L24 8.9h-9.461z"
      data-name="Pfad 13795"
    />
  </svg>
`;
export const rank4Svg = (classes = 'h-4') => html`
  <svg
    class="${classes} w-auto"
    viewBox="0 0 99 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#161615"
      d="m86.813 0-2.646 8.9H75l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L99 8.9h-9.461zm-25 0-2.646 8.9H50l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L74 8.9h-9.461zm-25 0-2.646 8.9H25l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L49 8.9h-9.461zm-25 0L9.167 8.9H0l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L24 8.9h-9.461z"
      data-name="Pfad 13795"
    />
  </svg>
`;
export const rank5Svg = (classes = 'h-4') => html`
  <svg
    class="${classes} w-auto"
    viewBox="0 0 124 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#161615"
      d="m111.813 0-2.646 8.9H100l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L124 8.9h-9.461zm-25 0-2.646 8.9H75l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L99 8.9h-9.461zm-25 0-2.646 8.9H50l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L74 8.9h-9.461zm-25 0-2.646 8.9H25l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L49 8.9h-9.461zm-25 0L9.167 8.9H0l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L24 8.9h-9.461z"
      data-name="Pfad 13795"
    />
  </svg>
`;
export const rank1superiorSvg = (classes = 'h-4') => html`
  <svg
    class="${classes} w-auto"
    viewBox="0 0 30 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="none" d="M0 0h30v24H0z" data-name="Rechteck 4818" />
    <path
      fill="#161615"
      d="m16.115 13.951 3.368 9.087-7.671-5.853L4.2 23.038l3.26-8.9L0 8.9h9.167L11.812 0l2.727 8.9H24Zm8.975-9.5L23.118.1l-2.485 4.375-4.7-3.646 2.188 6.617a10.647 10.647 0 0 1 9.4 0S29.844.991 29.844.747z"
      data-name="Pfad 13796"
    />
  </svg>
`;
export const rank2superiorSvg = (classes = 'h-4') => html`
  <svg
    class="${classes} w-auto"
    viewBox="0 0 55 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="none" d="M25 0h30v24H25z" data-name="Rechteck 4818" />
    <path
      fill="#161615"
      d="m41.115 13.951 3.368 9.087-7.671-5.853-7.612 5.853 3.26-8.9L25 8.9h9.167L36.812 0l2.727 8.9H49Zm8.975-9.5L48.118.1l-2.485 4.375-4.7-3.646 2.188 6.617a10.647 10.647 0 0 1 9.4 0S54.844.991 54.844.747z"
      data-name="Pfad 13796"
    />
    <path
      fill="#161615"
      d="M11.813 0 9.167 8.9H0l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L24 8.9h-9.461z"
      data-name="Pfad 13795"
    />
  </svg>
`;
export const rank3superiorSvg = (classes = 'h-4') => html`
  <svg
    class="${classes} w-auto"
    viewBox="0 0 80 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="none" d="M50 0h30v24H50z" data-name="Rechteck 4818" />
    <path
      fill="#161615"
      d="m66.115 13.951 3.368 9.087-7.671-5.853-7.612 5.853 3.26-8.9L50 8.9h9.167L61.812 0l2.727 8.9H74Zm8.975-9.5L73.118.1l-2.485 4.375-4.7-3.646 2.188 6.617a10.647 10.647 0 0 1 9.4 0S79.844.991 79.844.747z"
      data-name="Pfad 13796"
    />
    <path
      fill="#161615"
      d="m36.813 0-2.646 8.9H25l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L49 8.9h-9.461zm-25 0L9.167 8.9H0l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L24 8.9h-9.461z"
      data-name="Pfad 13795"
    />
  </svg>
`;
export const rank4superiorSvg = (classes = 'h-4') => html`
  <svg
    class="${classes} w-auto"
    viewBox="0 0 105 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="none" d="M75 0h30v24H75z" data-name="Rechteck 4818" />
    <path
      fill="#161615"
      d="m91.115 13.951 3.368 9.087-7.671-5.853-7.612 5.853 3.26-8.9L75 8.9h9.167L86.812 0l2.727 8.9H99Zm8.975-9.5L98.118.1l-2.485 4.375-4.7-3.646 2.188 6.617a10.647 10.647 0 0 1 9.4 0s2.323-6.455 2.323-6.699z"
      data-name="Pfad 13796"
    />
    <path
      fill="#161615"
      d="m61.813 0-2.646 8.9H50l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L74 8.9h-9.461zm-25 0-2.646 8.9H25l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L49 8.9h-9.461zm-25 0L9.167 8.9H0l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L24 8.9h-9.461z"
      data-name="Pfad 13795"
    />
  </svg>
`;
export const rank5superiorSvg = (classes = 'h-4') => html`
  <svg
    class="${classes} w-auto"
    viewBox="0 0 130 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="none" d="M100 0h30v24h-30z" data-name="Rechteck 4818" />
    <path
      fill="#161615"
      d="m116.115 13.951 3.368 9.087-7.671-5.853-7.612 5.853 3.26-8.9L100 8.9h9.167l2.645-8.9 2.727 8.9H124Zm8.975-9.5L123.118.1l-2.485 4.375-4.7-3.646 2.188 6.617a10.647 10.647 0 0 1 9.4 0s2.323-6.455 2.323-6.699z"
      data-name="Pfad 13796"
    />
    <path
      fill="#161615"
      d="m86.813 0-2.646 8.9H75l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L99 8.9h-9.461zm-25 0-2.646 8.9H50l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L74 8.9h-9.461zm-25 0-2.646 8.9H25l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L49 8.9h-9.461zm-25 0L9.167 8.9H0l7.457 5.238-3.257 8.9 7.617-5.853 7.67 5.853-3.367-9.087L24 8.9h-9.461z"
      data-name="Pfad 13795"
    />
  </svg>
`;

export const zoomInSvg = () =>
  html` <svg
    xmlns="http://www.w3.org/2000/svg"
    id="i_zoom_in"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect width="24" height="24" fill="none" />
    <path
      class="fill-chm-red"
      d="M7.1,1.5a5.6,5.6,0,1,0,3.658,9.825l.342.342V12.7l4.8,4.8,1.6-1.6-4.8-4.8H11.667l-.342-.342A5.588,5.588,0,0,0,7.1,1.5Zm0,1.6a4,4,0,1,1-4,4A3.99,3.99,0,0,1,7.1,3.1ZM6.3,4.7V6.3H4.7V7.9H6.3V9.5H7.9V7.9H9.5V6.3H7.9V4.7Zm0,0"
      transform="translate(2.5 2.5)"
    />
  </svg>`;

export const exportGpxSvg = (classes = 'fill-chm-red') =>
  html`<svg
    class="${classes}"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 22H4.92001C3.27001 22 1.92001 20.64 1.92001 18.98V11.02C1.92001 9.35 3.27001 8 4.92001 8H6.02001V10H4.92001C4.37001 10 3.92001 10.46 3.92001 11.02V18.98C3.92001 19.54 4.37001 20 4.92001 20H19C19.55 20 20 19.54 20 18.98V11.02C20 10.46 19.55 10 19 10H17.98V8H19C20.65 8 22 9.35 22 11.02V18.98C22 20.65 20.65 22 19 22ZM7.47001 12.73C6.89001 12.73 6.46001 12.92 6.11001 13.27C5.60001 13.78 5.59001 14.35 5.59001 15.32C5.59001 16.29 5.59001 16.87 6.11001 17.37C6.46001 17.71 6.89001 17.91 7.47001 17.91C8.01001 17.91 8.50001 17.75 8.91001 17.33C9.27001 16.95 9.40001 16.51 9.40001 15.78V15.04H7.48001V15.88H8.42001V16.07C8.42001 16.33 8.36001 16.56 8.19001 16.75C8.03001 16.93 7.79001 17.04 7.48001 17.04C7.21001 17.04 6.99001 16.94 6.85001 16.77C6.66001 16.56 6.60001 16.31 6.60001 15.33C6.60001 14.35 6.66001 14.11 6.85001 13.9C6.99001 13.74 7.21001 13.63 7.48001 13.63C7.99001 13.63 8.28001 13.89 8.40001 14.37H9.40001C9.27001 13.51 8.67001 12.74 7.48001 12.74L7.47001 12.73ZM14.07 14.36C14.07 15.23 13.45 15.95 12.39 15.95H11.41V17.87H10.41V12.77H12.39C13.45 12.77 14.07 13.49 14.07 14.36ZM13.08 14.36C13.08 13.94 12.79 13.66 12.34 13.66H11.41V15.06H12.34C12.79 15.06 13.08 14.77 13.08 14.36ZM18.38 12.77H17.24L16.36 14.42L15.47 12.77H14.33L15.81 15.25L14.23 17.86H15.37L16.36 16.08L17.34 17.86H18.48L16.9 15.25L18.38 12.77ZM16.61 5.79L12.02 1.55L7.45001 5.79L8.81001 7.26L11.01 5.22V11.03H13.01V5.19L15.24 7.26L16.6 5.79H16.61Z"
    />
  </svg>`;

export const importGpxSvg = (classes = 'fill-chm-red') =>
  html` <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect width="24" height="24" fill="none" />
    <path
      d="M16.98,20H2.99A3.011,3.011,0,0,1,0,16.98V9.02A3,3,0,0,1,2.99,6H4.08V8.01H2.99A1.012,1.012,0,0,0,2,9.03v7.96a1,1,0,0,0,.99,1.02H16.98a1,1,0,0,0,.98-1.02V9.03a1,1,0,0,0-.98-1.02H15.97V6h1.01a3.011,3.011,0,0,1,2.99,3.02v7.96A3,3,0,0,1,16.98,20M5.58,10.8a1.814,1.814,0,0,0-1.36.54c-.51.51-.52,1.08-.52,2.05s0,1.55.52,2.05a1.852,1.852,0,0,0,1.36.54,1.915,1.915,0,0,0,1.44-.58,2.028,2.028,0,0,0,.49-1.55v-.74H5.59v.84h.94v.19a.97.97,0,0,1-.23.68.916.916,0,0,1-.71.29.791.791,0,0,1-.63-.27c-.19-.21-.25-.46-.25-1.44s.06-1.22.25-1.43a.824.824,0,0,1,.63-.27.867.867,0,0,1,.92.74h1a1.821,1.821,0,0,0-1.92-1.63Zm6.6,1.63a1.563,1.563,0,0,1-1.68,1.59H9.52v1.92h-1v-5.1H10.5a1.563,1.563,0,0,1,1.68,1.59m-.99,0a.678.678,0,0,0-.74-.7H9.52v1.4h.93a.683.683,0,0,0,.74-.7m5.3-1.59H15.35l-.88,1.65-.89-1.65H12.44l1.48,2.48-1.58,2.61h1.14l.99-1.78.98,1.78h1.14l-1.58-2.61ZM13.17,3.32l-2.2,2.04V0H8.98V5.38L6.74,3.31,5.38,4.78,9.96,9.03l4.58-4.25L13.18,3.31Z"
      transform="translate(2 2)"
    />
  </svg>`;

export const createTourSvg = (classes = 'fill-chm-red') =>
  html` <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect width="24" height="24" fill="none" />
    <path
      d="M18.5,20.886l-.848-1.356C17.209,18.821,15,15.2,15,13.5a3.5,3.5,0,1,1,7,0c0,1.7-2.209,5.321-2.652,6.03ZM18.5,12A1.5,1.5,0,0,0,17,13.5a11.844,11.844,0,0,0,1.5,3.527A11.865,11.865,0,0,0,20,13.5,1.5,1.5,0,0,0,18.5,12M13,15H11V13h2ZM9,15H7V13H9ZM5,15H3V13H5ZM3.5,10.887,2.652,9.53C2.209,8.821,0,5.2,0,3.5a3.5,3.5,0,0,1,7,0C7,5.2,4.791,8.821,4.348,9.53ZM3.5,2A1.5,1.5,0,0,0,2,3.5,11.854,11.854,0,0,0,3.5,7.027,11.854,11.854,0,0,0,5,3.5,1.5,1.5,0,0,0,3.5,2"
      transform="translate(1 1.999)"
    />
  </svg>`;

export const editTourSvg = (classes = 'fill-chm-red') =>
  html`<svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="M17.914.5a.993.993 0 0 0-.707.293L2.5 15.5v4h4L21.207 4.793a1 1 0 0 0 0-1.414L18.621.793A.993.993 0 0 0 17.914.5Zm0 2.414 1.172 1.172-1.293 1.293-1.172-1.172Zm-2.707 2.707 1.172 1.172L5.672 17.5H4.5v-1.172Zm0 0"
    />
    <path d="M3 21h15v2H3z" />
  </svg>`;

export const copyTourSvg = (classes = 'fill-chm-red') =>
  html`<svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect width="24" height="24" fill="none" />
    <path
      d="M18,4H6A2,2,0,0,0,4,6V18a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2m0,14H6V6H18ZM2,2H16V0H2A2,2,0,0,0,0,2V16H2Z"
      transform="translate(2 2)"
    />
  </svg>`;

export const shareTourSvg = (classes = 'fill-chm-red') =>
  html`<svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect width="24" height="24" fill="none" />
    <path
      d="M11.2,15.771a.816.816,0,1,1,.815-.816.817.817,0,0,1-.815.816M21.044,12.4a2.242,2.242,0,0,1-4.407-.827L11.846,8.777a2.173,2.173,0,0,1-1.341.468,2.2,2.2,0,1,1,0-4.4,2.176,2.176,0,0,1,1.342.473l4.79-2.794a2.373,2.373,0,0,1-.026-.322,2.218,2.218,0,0,1,2.2-2.2h0a2.2,2.2,0,1,1,0,4.4,2.175,2.175,0,0,1-1.341-.468L12.679,6.722a1.977,1.977,0,0,1,0,.648l4.791,2.8A2.242,2.242,0,0,1,21.044,12.4M18.81,11.321h-.02a.556.556,0,0,0-.392.171.55.55,0,0,0-.156.4.569.569,0,1,0,.568-.567m0-8.553a.569.569,0,1,0,0-1.137h-.02a.557.557,0,0,0-.548.567.569.569,0,0,0,.568.571m-8.3,3.708h-.021a.558.558,0,0,0-.548.567.569.569,0,1,0,.569-.567m3.584,7.787v4.969H11.321V22H2.769V19.231H0V14.263a2.211,2.211,0,0,1,2.2-2.2h.569V9.294H6.912v1.631H4.4v1.137h7.489a2.213,2.213,0,0,1,2.2,2.2m-2.769,1.953V17.6h1.137V14.263a.569.569,0,0,0-.57-.569H2.2a.569.569,0,0,0-.57.569V17.6H2.769V16.216ZM9.69,17.847H4.4v2.521H9.69Z"
      transform="translate(1.454 1)"
    />
  </svg>`;

export const editFilterSvg = (classes = 'fill-chm-red') =>
  html`<svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="M17.914.5a.993.993 0 0 0-.707.293L2.5 15.5v4h4L21.207 4.793a1 1 0 0 0 0-1.414L18.621.793A.993.993 0 0 0 17.914.5Zm0 2.414 1.172 1.172-1.293 1.293-1.172-1.172Zm-2.707 2.707 1.172 1.172L5.672 17.5H4.5v-1.172Zm0 0"
    />
  </svg>`;

export const bookableSvg = (classes = 'fill-chm-red') =>
  html`<svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="M10.333 2.833A1.678 1.678 0 0 0 8.667 4.5v.833H7V4.5H5.333v.833H3.667A1.678 1.678 0 0 0 2 7v12.5a1.678 1.678 0 0 0 1.667 1.667h5V19.5H7V7.833H5.333V19.5H3.667V7h16.666v3.333H22V7a1.678 1.678 0 0 0-1.667-1.667h-1.666V4.5H17v.833h-1.667V4.5a1.678 1.678 0 0 0-1.666-1.667Zm0 1.667h3.333v.833h-3.333Zm5 5a1.678 1.678 0 0 0-1.667 1.667V12H12a1.678 1.678 0 0 0-1.667 1.667V19.5A1.678 1.678 0 0 0 12 21.166h8.333A1.678 1.678 0 0 0 22 19.5v-5.834a1.678 1.678 0 0 0-1.667-1.667h-1.666v-.833A1.678 1.678 0 0 0 17 9.5Zm0 1.667H17V12h-1.667ZM12 13.666h8.333v5.833H12Zm0 0"
    />
  </svg>`;

export const listSvg = (classes = 'fill-chm-red') =>
  html` <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect width="24" height="24" fill="none" />
    <path
      d="M3,4.5A1.5,1.5,0,1,0,4.5,6,1.5,1.5,0,0,0,3,4.5ZM7,5V7H22V5ZM3,10.5A1.5,1.5,0,1,0,4.5,12,1.5,1.5,0,0,0,3,10.5ZM7,11v2H22V11ZM3,16.5A1.5,1.5,0,1,0,4.5,18,1.5,1.5,0,0,0,3,16.5ZM7,17v2H22V17Z"
      transform="translate(0.5)"
    />
  </svg>`;

export const undoDrawSvg = (classes = 'fill-chm-red') =>
  html`<svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="M21.415 12.301a1 1 0 0 1-1.39-.28c-2.11-3.17-5.5-4.67-8.43-3.74-2.28.72-3.72 2.82-4.06 5.72l2.84-2.07a1.002 1.002 0 0 1 1.18 1.62l-5.48 3.98-3.88-5.33a1.002 1.002 0 0 1 1.62-1.18L5.6 13.466c.49-3.56 2.41-6.15 5.4-7.1 3.78-1.2 8.08.62 10.7 4.54a1 1 0 0 1-.28 1.39"
    />
  </svg>`;

export const redoDrawSvg = (classes = 'fill-chm-red') => html`
  <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M24 24H0V0h24z" />
    <path
      d="m21.664 12.195-3.88 5.33-5.48-3.98a1.002 1.002 0 1 1 1.18-1.62l2.84 2.07c-.34-2.91-1.78-5-4.06-5.72-2.93-.93-6.32.58-8.43 3.74a1.003 1.003 0 0 1-1.67-1.11c2.61-3.92 6.91-5.74 10.7-4.54 2.99.95 4.91 3.54 5.4 7.1l1.78-2.45a1.002 1.002 0 1 1 1.62 1.18"
    />
  </svg>
`;

export const saveDrawSvg = (classes = 'fill-chm-red') => html`
  <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="M12 2a10 10 0 1 0 10 10A10.015 10.015 0 0 0 12 2Zm0 2a8 8 0 1 1-8 8 7.985 7.985 0 0 1 8-8Zm4.293 4.293L10 14.586l-2.293-2.293-1.414 1.414L10 17.414l7.707-7.707Z"
    />
  </svg>
`;

export const cancelDrawSvg = (classes = 'fill-chm-red') => html`
  <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M12 2a10 10 0 1 0 10 10A10.015 10.015 0 0 0 12 2Zm0 2a8 8 0 0 1 6.318 12.9L7.1 5.682A7.965 7.965 0 0 1 12 4ZM5.682 7.1 16.9 18.318A8 8 0 0 1 5.682 7.1Z"
    />
    <path fill="none" d="M0 0h24v24H0z" />
  </svg>
`;

export const reverseDrawSvg = (classes = 'fill-chm-red') =>
  html` <svg
    class="${classes}"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="m22.11 7.888-4.25 4.58a1 1 0 0 1-1.46-1.36l2.06-2.22H3a1 1 0 0 1 0-2h15.45l-2.05-2.21a1 1 0 0 1 1.46-1.36L22.11 7.9Zm-1.11 9H5.68l2.05-2.21a1 1 0 1 0-1.46-1.36L2.02 17.9l4.25 4.58a1 1 0 0 0 1.46-1.36L5.67 18.9H21a1 1 0 0 0 0-2Z"
    />
  </svg>`;

export const poiAddSvg = (classes = 'fill-chm-red') => html`
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="${classes}"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="M12.58 11.426a6.976 6.976 0 0 0 1.984 1.13C13.665 17.044 8.353 23 8.353 23S2 15.89 2 11.353a6.334 6.334 0 0 1 8.043-6.118A7.234 7.234 0 0 0 10 6a6.939 6.939 0 0 0 .177 1.547 4.181 4.181 0 0 0-1.824-.429 4.238 4.238 0 0 0-4.236 4.235c0 2.134 2.2 5.679 4.236 8.322 2.009-2.619 4.181-6.112 4.227-8.249m-6.345-.073a2.118 2.118 0 1 0 2.118-2.118 2.118 2.118 0 0 0-2.118 2.118M17 1a5 5 0 1 1-5 5 5 5 0 0 1 5-5"
    />
    <path
      fill="#fff"
      d="M17.576 5.425V2.751h-1.151v2.674H13.75v1.149h2.675v2.677h1.15V6.575h2.675v-1.15Z"
    />
  </svg>
`;

export const snapActiveSvg = (classes = 'fill-chm-red') =>
  html`<svg
    xmlns="http://www.w3.org/2000/svg"
    class="${classes}"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect width="24" height="24" fill="none" />
    <path
      d="M12.6,11.178v5.4H10.8v-5.4a3.6,3.6,0,1,0-7.2,0v5.4H1.8v-5.4a5.383,5.383,0,0,1,5.366-5.4H7.2a5.34,5.34,0,0,1,1.824.333A6.9,6.9,0,0,1,8.927,5a7.082,7.082,0,0,1,.047-.789A7.162,7.162,0,0,0,0,11.178v10.8H5.4v-10.8a1.8,1.8,0,1,1,3.6,0v10.8h5.4V11.827a6.937,6.937,0,0,1-1.8-.67Zm-9,9H1.8v-1.8H3.6Zm9,0H10.8v-1.8h1.8Z"
      transform="translate(3 0.023)"
    />
    <path
      d="M20.927,5a5,5,0,1,1-5-5,5,5,0,0,1,5,5"
      transform="translate(3.021 0.001)"
      fill="#ad091d"
    />
    <path
      d="M18.28,2.5,14.831,5.953,13.574,4.694l-.777.777L14.831,7.5l4.226-4.223Z"
      transform="translate(3.021 0.001)"
      fill="#fff"
    />
  </svg>`;

export const snapInactiveSvg = (classes = 'fill-chm-red') =>
  html` <svg
    xmlns="http://www.w3.org/2000/svg"
    class="${classes}"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect width="24" height="24" fill="none" />
    <path
      d="M14.391,18l0-10.8A7.194,7.194,0,1,0,0,7.2V18H5.367V7.208a1.833,1.833,0,0,1,.56-1.286,1.752,1.752,0,0,1,1.3-.512A1.809,1.809,0,0,1,9.023,7.2V18Zm-1.757-1.757H10.781V14.39h1.853ZM3.609,7.2v5.439H1.757V7.192a5.439,5.439,0,0,1,10.877,0v5.438H10.781V7.182A3.549,3.549,0,0,0,7.255,3.656H7.212A3.588,3.588,0,0,0,3.609,7.2M1.757,14.39H3.609v1.853H1.758Z"
      transform="translate(3 4)"
      fill="#d4021c"
    />
  </svg>`;

export const profileSvg = (classes = 'fill-chm-red') => html`
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="${classes}"
    width="24"
    height="24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="m7.7 19.854-2.013-4.05-1.887 3.7-1.8-.917 3.707-7.291 2.073 4.17 6.144-11.319L22 18.551l-1.765.99-6.282-11.2Z"
    />
  </svg>
`;

export const importTourSvg = (classes = 'fill-chm-red') =>
  html`<svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_11_10732)">
      <path
        d="M10 2C8.895 2 8 2.895 8 4V9H10V4H18V20H10V15H8V20C8 21.105 8.895 22 10 22H18C19.105 22 20 21.105 20 20V4C20 2.895 19.105 2 18 2H10ZM12 8V11H4V13H12V16L16 12L12 8Z"
        class="${classes}"
      />
    </g>
    <defs>
      <clipPath id="clip0_11_10732">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg> `;

export const menuSvg = (classes = 'fill-chm-red w-[24px] h-[20px]') =>
  html`<svg
    class="${classes}"
    viewBox="0 0 44 38"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0V5.42857H44V0H0ZM0 16.2857V21.7143H44V16.2857H0ZM0 32.5714V38H44V32.5714H0Z"
    />
  </svg> `;

const SVG_PATHS: Record<FileType, string> = {
  pdf: 'M4 0C2.90575 0 2 0.905745 2 2V7C0.895 7 0 7.895 0 9V14C0 15.105 0.895 16 2 16V18C2 19.0943 2.90575 20 4 20H16C17.0943 20 18 19.0943 18 18V5L13 0H4ZM4 2H12V6H16V7H4V2ZM2 9H3.5C4.328 9 5 9.672 5 10.5C5 11.328 4.328 12 3.5 12H3V14H2V9ZM11 9H14V10H12V11H13.5996V12H12V14H11V9ZM6 9.02344H7.62891C9.21091 9.02344 10 9.97491 10 11.1289V11.9004C10 13.0684 9.20923 14 7.61523 14H6V9.02344ZM3 10V11H3.5C3.776 11 4 10.776 4 10.5C4 10.224 3.776 10 3.5 10H3ZM7 10.0234V13H7.61523C8.24723 13 9 12.8084 9 11.9004V11.1289C9 10.2149 8.25491 10.0234 7.62891 10.0234H7ZM4 16H16V18H4V16Z',
  doc: 'M4 0C2.90575 0 2 0.905745 2 2V7C0.895 7 0 7.895 0 9V14C0 15.105 0.895 16 2 16V18C2 19.0943 2.90575 20 4 20H16C17.0943 20 18 19.0943 18 18V14V9V5L13 0H4ZM4 2H12V6H16V7H4V2ZM14 9C15.105 9 16 9.895 16 11H15C15 10.449 14.551 10 14 10C13.449 10 13 10.449 13 11V12C13 12.551 13.449 13 14 13C14.551 13 15 12.551 15 12H16C16 13.105 15.105 14 14 14C12.895 14 12 13.105 12 12V11C12 9.895 12.895 9 14 9ZM2 9.02344H3.62891C5.21191 9.02344 6 9.97491 6 11.1289V11.9004C6 13.0684 5.20923 14 3.61523 14H2V9.02344ZM9 9.06445C10.864 9.03445 11 10.8424 11 11.1914V12C11 12.342 10.9138 14.1015 9.00781 14.0605C6.96681 14.0185 7 12.342 7 12V11.1914C7 10.8404 7.167 9.09345 9 9.06445ZM9.00195 9.88672C8.05695 9.90572 8 10.9535 8 11.1855V12C8 12.222 8.15472 13.2592 9.01172 13.2422C9.83372 13.2272 10 12.221 10 12V11.1855C10 10.9535 9.88795 9.86872 9.00195 9.88672ZM3 10.0234V13H3.61523C4.24723 13 5 12.8084 5 11.9004V11.1289C5 10.2149 4.25491 10.0234 3.62891 10.0234H3ZM4 16H16V18H4V16Z',
  eps: 'M4 0C2.90575 0 2 0.905745 2 2V7C0.895 7 0 7.895 0 9V14C0 15.105 0.895 16 2 16V18C2 19.0943 2.90575 20 4 20H16C17.0943 20 18 19.0943 18 18V5L13 0H4ZM4 2H12V6H16V18H4V16H13C14.105 16 15 15.105 15 14V9C15 7.895 14.105 7 13 7H4V2ZM2 9H5V10H3V11H4.59961V12H3V13H5V14H2V9ZM6 9H7.5C8.328 9 9 9.672 9 10.5C9 11.328 8.328 12 7.5 12H7V14H6V9ZM11.4902 9C12.9132 9.041 13 10.2829 13 10.5039H12.0312C12.0312 10.4009 12.0487 9.80664 11.4727 9.80664C11.2977 9.80664 10.9055 9.88419 10.9355 10.3672C10.9645 10.8102 11.5473 11.0194 11.6543 11.0664C11.8783 11.1484 12.9871 11.6424 12.9961 12.6504C12.9981 12.8644 12.9428 13.985 11.5098 14C9.95077 14.017 9.8457 12.6754 9.8457 12.3984H10.8203C10.8203 12.5454 10.8328 13.2562 11.5098 13.2012C11.9168 13.1672 12.0055 12.8743 12.0195 12.6562C12.0425 12.2893 11.6923 12.0686 11.3223 11.8906C10.8023 11.6406 9.97984 11.3334 9.96484 10.3594C9.95184 9.48238 10.5962 8.975 11.4902 9ZM7 10V11H7.5C7.776 11 8 10.776 8 10.5C8 10.224 7.776 10 7.5 10H7Z',
  jpg: 'M4 0C2.90575 0 2 0.905745 2 2V7C0.895 7 0 7.895 0 9V14C0 15.105 0.895 16 2 16V18C2 19.0943 2.90575 20 4 20H16C17.0943 20 18 19.0943 18 18V5L13 0H4ZM4 2H12V6H16V7H4V2ZM4 9H5V12.5C5 13.328 4.328 14 3.5 14C2.672 14 2 13.328 2 12.5H3C3 12.776 3.224 13 3.5 13C3.776 13 4 12.776 4 12.5V9ZM6 9H7.5C8.328 9 9 9.672 9 10.5C9 11.328 8.328 12 7.5 12H7V14H6V9ZM12.0352 9.00195C13.2072 9.00295 13.6733 9.53895 13.8633 10.002H12.5703H12C11.118 9.98895 11 10.8569 11 11.0859V11.9141C11 12.1461 11.2068 13.012 12.1758 13.002C12.4588 12.999 12.976 12.8363 13 12.8223L12.9785 12H12L12.0215 11H14V13.3809C13.94 13.4369 13.2914 13.961 12.1074 13.998C11.7854 14.008 10.132 14.0381 10 11.9141V11.0918C10 10.7368 9.95816 8.99995 12.0352 9.00195ZM7 10V11H7.5C7.776 11 8 10.776 8 10.5C8 10.224 7.776 10 7.5 10H7ZM4 16H16V18H4V16Z',
  png: 'M4 0C2.90575 0 2 0.905745 2 2V7C0.895 7 0 7.895 0 9V14C0 15.105 0.895 16 2 16V18C2 19.0943 2.90575 20 4 20H16C17.0943 20 18 19.0943 18 18V5L13 0H4ZM4 2H12V6H16V7H4V2ZM2 9H3.5C4.328 9 5 9.672 5 10.5C5 11.328 4.328 12 3.5 12H3V14H2V9ZM9 9H10V14H8.8457L7 10.7363V14H6V9.02344H7.1543L9 12.2676V9ZM13.0352 9.00195C14.2072 9.00295 14.6733 9.53895 14.8633 10.002H13C12.118 9.98895 12 10.8569 12 11.0859V11.9141C12 12.1461 12.2068 13.012 13.1758 13.002C13.4588 12.999 13.976 12.8363 14 12.8223L13.9785 12H13L13.0215 11H15V13.3809C14.94 13.4369 14.2914 13.961 13.1074 13.998C12.7854 14.008 11.132 14.0381 11 11.9141V11.0918C11 10.7368 10.9582 8.99995 13.0352 9.00195ZM3 10V11H3.5C3.776 11 4 10.776 4 10.5C4 10.224 3.776 10 3.5 10H3ZM4 16H16V18H4V16Z',
  ppt: 'M4 0C2.90575 0 2 0.905745 2 2V7C0.895 7 0 7.895 0 9V14C0 15.105 0.895 16 2 16V18C2 19.0943 2.90575 20 4 20H16C17.0943 20 18 19.0943 18 18V5L13 0H4ZM4 2H12V6H16V18H4V16H13C14.105 16 15 15.105 15 14V9C15 7.895 14.105 7 13 7H4V2ZM2 9H3.5C4.328 9 5 9.672 5 10.5C5 11.328 4.328 12 3.5 12H3V14H2V9ZM6 9H7.5C8.328 9 9 9.672 9 10.5C9 11.328 8.328 12 7.5 12H7V14H6V9ZM10 9H13V10H12V14H11V10H10V9ZM3 10V11H3.5C3.776 11 4 10.776 4 10.5C4 10.224 3.776 10 3.5 10H3ZM7 10V11H7.5C7.776 11 8 10.776 8 10.5C8 10.224 7.776 10 7.5 10H7Z',
  xlsx: 'M4 0C2.90575 0 2 0.905745 2 2V7C0.895 7 0 7.895 0 9V14C0 15.105 0.895 16 2 16V18C2 19.0943 2.90575 20 4 20H16C17.0943 20 18 19.0943 18 18V5L13 0H4ZM4 2H12V6H16V7H4V2ZM2.02539 9H3.27344L3.99805 10.7168L4.72461 9H5.9707L4.70312 11.4785L6 14H4.73828L3.99805 12.252L3.26172 14H2L3.29492 11.4785L2.02539 9ZM7 9H8V13H10V14H7V9ZM12.4902 9C13.9132 9.041 14 10.2829 14 10.5039H13.0312C13.0312 10.4009 13.0487 9.80664 12.4727 9.80664C12.2977 9.80664 11.9055 9.88419 11.9355 10.3672C11.9645 10.8102 12.5473 11.0194 12.6543 11.0664C12.8783 11.1484 13.9871 11.6424 13.9961 12.6504C13.9981 12.8644 13.9428 13.985 12.5098 14C10.9508 14.017 10.8457 12.6754 10.8457 12.3984H11.8203C11.8203 12.5454 11.8328 13.2562 12.5098 13.2012C12.9168 13.1672 13.0055 12.8743 13.0195 12.6562C13.0425 12.2893 12.6923 12.0686 12.3223 11.8906C11.8023 11.6406 10.9798 11.3334 10.9648 10.3594C10.9518 9.48238 11.5962 8.975 12.4902 9ZM4 16H16V18H4V16Z',
  zip: 'M4 0C2.90575 0 2 0.905745 2 2V7C0.895 7 0 7.895 0 9V14C0 15.105 0.895 16 2 16V18C2 19.0943 2.90575 20 4 20H16C17.0943 20 18 19.0943 18 18V5L13 0H4ZM4 2H12V6H16V18H4V16H11C12.105 16 13 15.105 13 14V9C13 7.895 12.105 7 11 7H4V2ZM2 9H5V10L3.32422 13H5V14H2V13L3.70508 10H2V9ZM5.99414 9H7V14H5.99414V9ZM8 9H9H9.5C10.328 9 11 9.672 11 10.5C11 11.328 10.328 12 9.5 12H9V14H8V12V9ZM9 10V11H9.5C9.776 11 10 10.776 10 10.5C10 10.224 9.776 10 9.5 10H9Z',
};

export function fileDownloadSvg(classes: string, fileType: FileType) {
  if (Object.keys(SVG_PATHS).includes(fileType)) {
    return html`<svg
      class="${classes}"
      viewBox="0 0 18 20"
      width="18"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d=${SVG_PATHS[fileType]} fill="black" />
    </svg>`;
  } else {
    return downloadSvg('fill-black');
  }
}

export const resetFilterSvg = (classes = 'fill-chm-red hover:fill-black') =>
  html` <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="${classes}"
  >
    <path
      d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12H20C20 16.411 16.411 20 12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C14.206 4 16.2027 4.90048 17.6504 6.34961L15 9H22V2L19.0605 4.93945C17.2518 3.12627 14.7573 2 12 2Z"
    />
  </svg>`;

export const collapseArrowSvg = (classes: string) =>
  html`<svg
    class="${classes}"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 10 6"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M9 5 5 1 1 5"
    />
  </svg>`;

export const importToListSvg = (classes: string) => html`
  <svg
    class=${classes}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.08 22.43H5C3.35 22.43 2 21.07 2 19.41V11.45C2 9.78 3.35 8.43 5 8.43H6.1V10.43H5C4.45 10.43 4 10.89 4 11.45V19.41C4 19.97 4.45 20.43 5 20.43H19.08C19.63 20.43 20.08 19.97 20.08 19.41V11.45C20.08 10.89 19.63 10.43 19.08 10.43H18.06V8.43H19.08C20.73 8.43 22.08 9.78 22.08 11.45V19.41C22.08 21.08 20.73 22.43 19.08 22.43ZM7.53 6.86L12.12 11.1L16.69 6.86L15.33 5.39L13.13 7.43V2H11.13V7.46L8.9 5.39L7.54 6.86H7.53ZM8.1 15.16C7.62 15.16 7.22 15.55 7.22 16.04C7.22 16.53 7.61 16.92 8.1 16.92C8.42 16.92 8.7 16.75 8.85 16.5C8.93 16.37 8.98 16.21 8.98 16.05C8.98 15.57 8.59 15.17 8.1 15.17V15.16ZM17.05 15.54H9.8V16.54H17.05V15.54ZM8.1 17.65C7.62 17.65 7.22 18.04 7.22 18.53C7.22 19.02 7.61 19.41 8.1 19.41C8.42 19.41 8.7 19.24 8.85 18.99C8.93 18.86 8.98 18.7 8.98 18.54C8.98 18.06 8.59 17.66 8.1 17.66V17.65ZM9.8 18.03V19.03H17.05V18.03H9.8ZM18.05 11.57V14.45H6.03V11.57H18.05ZM8.84 13.06C8.84 12.58 8.45 12.18 7.96 12.18C7.47 12.18 7.08 12.57 7.08 13.06C7.08 13.55 7.47 13.94 7.96 13.94C8.28 13.94 8.56 13.77 8.71 13.52C8.79 13.39 8.84 13.23 8.84 13.07V13.06ZM17.05 12.52H9.8V13.52H17.05V12.52Z"
    />
  </svg>
`;
