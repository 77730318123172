import {msg} from '@lit/localize';
import type {
  DetailedRecordedTour,
  DetailedTour,
  RecordedTourMeta,
  TourBaseProperties,
  TourCommonMeta,
  TourMeta,
  TourOwnProperties,
  TourTimeType,
} from '../apis/api-tours.ts';
import {modal$} from '../stores/store-main.ts';
import {activeTrackId$} from '../stores/store-tours.ts';

const meterFormat = Intl.NumberFormat('de-CH', {
  style: 'unit',
  unit: 'meter',
  maximumFractionDigits: 0,
});

const kilometerFormat = Intl.NumberFormat('de-CH', {
  style: 'unit',
  unit: 'kilometer',
  maximumFractionDigits: 1,
});

const speedFormat = Intl.NumberFormat('de-CH', {
  style: 'unit',
  unit: 'kilometer-per-hour',
  maximumFractionDigits: 0,
});

const numberFormat = Intl.NumberFormat('de-CH', {
  maximumFractionDigits: 0,
});

export function formatMeter(meters: number) {
  return meterFormat.format(meters);
}

export function formatAltitude(meters: number) {
  return numberFormat.format(meters).concat(' ').concat(msg('m.a.s.l.'));
}

export function formatKilometer(kilometers: number) {
  return kilometerFormat.format(kilometers);
}

export function formatMeterOrKilometer(meters: number) {
  return meters < 1000 ? formatMeter(meters) : formatKilometer(meters / 1000);
}

export function formatSpeed(speed: number) {
  return speedFormat.format(speed);
}

export const TIME_TYPES: Record<TourTimeType, string> = {
  velo: 'bg-cycle-icon',
  wander: 'bg-hike-icon',
  winter_hiking: 'bg-winter-hike-icon',
  snowshoe_trekking: 'bg-snowshoe-icon',
  mountain_biking: 'bg-mountain-bike-icon',
  canoeing: 'bg-canoe-icon',
  skating: 'bg-skate-icon',
  cross_country_skiing: 'bg-cross-country-icon',
  sledging: 'bg-sledge-icon',
  no: 'bg-no-time-type-icon',
};

export function getTourTime(timetype: TourTimeType, meta: TourMeta): number {
  let time: number = 0;
  if (isRecordedTourMeta(meta)) {
    time = meta.trackedTimeTotal;
  } else if (isTourMeta(meta)) {
    switch (timetype) {
      case 'velo':
        time = meta.biking;
        break;
      case 'winter_hiking':
        time = meta.winterHiking;
        break;
      case 'snowshoe_trekking':
        time = meta.snowshoeTrekking;
        break;
      case 'wander':
        time = meta.walking;
        break;
      case 'mountain_biking':
        time = meta.mountainBiking;
        break;
    }
  }
  return time;
}

export function getTourTimeString(
  timetype: TourTimeType,
  meta: TourMeta,
): string {
  return formatTime(getTourTime(timetype, meta));
}

export function formatTime(time: number) {
  if (!time) return '-';
  const hours = String(Math.floor(time / 60));
  const minutes = String(Math.round(time % 60));
  return `${hours.length > 1 ? hours : `0${hours}`}\u2009h ${minutes.length > 1 ? minutes : `0${minutes}`}\u2009min`;
}

export function parseTourDate(
  track: TourBaseProperties | TourOwnProperties,
): Date {
  const date = track.userdate || track.modifiedAt;
  return new Date(date);
}

export function formatTourDate(stringDate: string | undefined): string {
  return stringDate ? new Date(stringDate).toLocaleDateString('de-CH') : '-';
}

export function isNormalTour(
  tour: TourBaseProperties,
): tour is TourBaseProperties<TourMeta> {
  return tour.type === 'normal';
}

export function isRecordedTour(
  tour: TourBaseProperties,
): tour is TourBaseProperties<RecordedTourMeta> {
  return tour.type === 'recordedtrack';
}

export function isNormalDetailedTour(
  trackDetails: DetailedTour | DetailedRecordedTour,
): trackDetails is DetailedTour {
  return isNormalTour(trackDetails.properties);
}

export function isTourMeta(
  meta: TourMeta | RecordedTourMeta,
): meta is TourMeta {
  return (meta as TourMeta).biking !== undefined;
}

export function isRecordedTourMeta(
  meta: TourMeta | RecordedTourMeta | TourCommonMeta,
): meta is RecordedTourMeta {
  return meta === null ? false : !isTourMeta(meta) && !isRoute(meta);
}

export function isRoute(
  meta: TourMeta | RecordedTourMeta | TourCommonMeta,
): meta is TourCommonMeta {
  return (
    (meta as TourMeta).biking === undefined &&
    (meta as RecordedTourMeta).trackedTime === undefined
  );
}

export function updateScale(
  x: d3.ScaleLinear<number, number>,
  y: d3.ScaleLinear<number, number>,
  width: number,
  height: number,
) {
  // Flattened array of [xMax,ratioYOverX].
  const ratiosXY = [0, 0.5, 0.33, 0.2, 0.1, 0.05];
  const ratiosMinDist = [0, 2000, 5000, 20000, 100000, Infinity];
  console.assert(ratiosXY.length === ratiosMinDist.length);

  let i;

  const xDomain = x.domain();

  let yDomain = y.domain();

  // Add padding to Y domain
  const padding = (yDomain[1] - yDomain[0]) * 0.1;
  y.domain([yDomain[0] - padding, yDomain[1] + padding]);
  yDomain = y.domain();

  const xMin = xDomain[0];
  const xMax = xDomain[1];
  const yMin = yDomain[0];
  const yMax = yDomain[1];

  // Search for the best XY ratio
  let yDomainLength = 0;
  i = 0;
  let reverse = false;
  // The algorithm is:
  // - advance to reach the expected ratio;
  // - if the computed ydomain would not be large enough, reverse direction;
  // - go backwards until a good ydomain is found;
  // - if none is found keep the ydomain values from d3.
  while (true) {
    if (!reverse && xMax > ratiosMinDist[i]) {
      // advance in the ratio table
      ++i;
      continue;
    }

    const ratioYOverX = ratiosXY[i];
    const xResolution = (xMax - xMin) / width;
    yDomainLength = ratioYOverX * xResolution * height;

    if (i > 0 && yDomainLength < yMax - yMin) {
      // not enough space with this ratio to show all the
      // y values. Go back to try another ratio.
      reverse = true;
      --i;
      continue;
    }

    break;
  }

  // Apply XY ratio
  if (yDomainLength) {
    const yMean = (yMax + yMin) / 2;
    const yHalfDomain = yDomainLength / 2;
    y.domain([yMean - yHalfDomain, yMean + yHalfDomain]).nice();
  }

  // Lower bound for y-axis
  if (y.domain()[0] < 0) {
    const shift = 0 - y.domain()[0];
    // For yLowerBound=0 and y0=-50, y is shifted 50m up
    y.domain([0, y.domain()[1] + shift]).nice();
  }
}

export function placeholderDateNow() {
  const dt = new Date();
  const padFunc = (nr: number, len = 2, char = '0') =>
    `${nr}`.padStart(len, char);
  const dateNow = `${padFunc(dt.getDate())}.${padFunc(dt.getMonth() + 1)}.${dt.getFullYear()} ${padFunc(dt.getHours())}:${padFunc(dt.getMinutes())}`;
  return `${msg('Tour')} ${dateNow}`;
}

export function getCopyName(name: string): string {
  const copyName = `${msg('Copy of ')} ${name}`;
  if (copyName.length > 40) {
    return copyName.slice(0, 37) + '...';
  }
  return copyName;
}

export function confirmDiscardChanges(): Promise<boolean> {
  return new Promise((resolve) => {
    modal$.next({
      type: 'confirm',
      data: {
        title: activeTrackId$.value
          ? msg(
              'Do you want to leave the page without saving the changes to the tour?',
            )
          : msg('Do you want to leave the page without saving the drawn tour?'),
        text: msg(
          'You are currently drawing a tour and the last changes have not yet been saved. Would you like to finish drawing and save the tour before leaving the page?',
        ),
        cancelBtnText: msg('Continue drawing'),
        confirmBtnText: msg('Leave page', {desc: 'Confirm discard tour modal'}),
        onCancel: () => resolve(false),
        onConfirm: () => resolve(true),
        onClose: () => resolve(false),
      },
    });
  });
}
